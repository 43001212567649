import { useCancellablePromise, useLogAndShowNotification } from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { AccProject, BIMAccount, MetaInfo } from 'mid-types';
import { useCallback, useEffect, useState } from 'react';
import text from '../../addins.text.json';
import { DropdownItem } from '../../components/Dropdown/Dropdown.types';
import { useAsyncFetchData, useAsyncFetchDataWithArgs } from '../../hooks/http/hooks';
import { SELECTED_ACCOUNT_ID, SELECTED_PROJECT_ID } from './constants';

export interface UseACCDocSelectionState {
  accounts: DropdownItem[] | undefined;
  accountsLoading: boolean;
  projects: DropdownItem[] | undefined;
  projectsLoading: boolean;
  projectsDisabled: boolean;
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
  handleSelectAccount: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleSelectProject: (changes: { selectedItem?: DropdownItem | null }) => void;
}

export const useACCDocSelection = (): UseACCDocSelectionState => {
  const [selectedAccount, setSelectedAccount] = useState<MetaInfo | undefined>();
  const [selectedAccountIdDependencyList, setSelectedAccountIdDependencyList] = useState<string[]>();
  const [selectedProject, setSelectedProject] = useState<MetaInfo | undefined>();
  const [projectsDisabled, setProjectsDisabled] = useState(true);
  const forgeApiService = getForgeApiServiceInstance();
  const cancellablePromise = useCancellablePromise();

  const getAccounts = useCallback(
    () => cancellablePromise(forgeApiService.getAccounts()),
    [forgeApiService, cancellablePromise],
  );
  const { data: accounts, loading: accountsLoading, error: accountsError } = useAsyncFetchData<BIMAccount[]>(getAccounts);

  useLogAndShowNotification(accountsError, text.notificationGetAccountsFailed);

  const getProjects = useCallback(
    (accountId: string) => cancellablePromise(forgeApiService.getProjects(accountId)),
    [forgeApiService, cancellablePromise],
  );
  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useAsyncFetchDataWithArgs<AccProject[]>(getProjects, selectedAccountIdDependencyList);

  useLogAndShowNotification(projectsError, text.notificationGetProjectsFailed);

  useEffect(() => {
    if (selectedAccount?.id) {
      setProjectsDisabled(false);
      setSelectedProject(undefined);
    }
  }, [selectedAccount?.id]);

  useEffect(() => {
    const selectedAccountIdInSession = sessionStorage.getItem(SELECTED_ACCOUNT_ID);
    if (selectedAccountIdInSession && accounts !== null && accounts.length > 0) {
      const selectedAccount = accounts.find((account) => account.id === selectedAccountIdInSession);
      if (selectedAccount) {
        setSelectedAccount({
          id: selectedAccount.id,
          name: selectedAccount.name,
        });
      } else {
        sessionStorage.removeItem(SELECTED_ACCOUNT_ID);
      }
      setSelectedAccountIdDependencyList([selectedAccountIdInSession]);
    }
  }, [accounts]);

  useEffect(() => {
    const selectedProjectIdInSession = sessionStorage.getItem(SELECTED_PROJECT_ID);
    if (selectedProjectIdInSession && projects !== null && projects.length > 0) {
      const selectedProject = projects.find((prj) => prj.id === selectedProjectIdInSession);
      if (selectedProject) {
        setSelectedProject({
          id: selectedProject.id,
          name: selectedProject.name,
        });
      } else {
        sessionStorage.removeItem(SELECTED_PROJECT_ID);
      }
    }
  }, [projects]);

  const handleSelectAccount = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem?.id) {
      const { id, value } = selectedItem;

      const idStringified = id.toString();

      setSelectedAccount({
        id: idStringified,
        name: value.toString(),
      });
      setSelectedAccountIdDependencyList([idStringified]);
      sessionStorage.setItem(SELECTED_ACCOUNT_ID, idStringified);
    }
  };

  const handleSelectProject = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem?.id) {
      const { id, value } = selectedItem;
      setSelectedProject({
        id: id.toString(),
        name: value.toString(),
      });
      sessionStorage.setItem(SELECTED_PROJECT_ID, id.toString());
    }
  };

  const _transformDropdownItemsForAccounts = (list: BIMAccount[] | null): DropdownItem[] | undefined => {
    if (list && list.length) {
      return list.map((account) => ({
        id: account.id,
        value: account.name,
        label: account.name,
      }));
    }
  };
  const _transformDropdownItemsForProjects = (list: AccProject[] | null): DropdownItem[] | undefined => {
    if (list && list.length) {
      return list.map((project) => ({
        id: project.id,
        value: project.name,
        label: project.name,
      }));
    }
  };

  return {
    accounts: _transformDropdownItemsForAccounts(accounts),
    accountsLoading,
    projects: _transformDropdownItemsForProjects(projects),
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  };
};
