import {
  PrintMessageDialog,
  ProductCustomizationForm,
  productCustomizationTestIds,
  usePrintMessageDialog,
} from '@mid-react-common/addins';
import { StateSetter } from '@mid-react-common/common';
import Blockly from 'blockly';
import DataContext from 'context/DataStore/Data.context';
import { ErrorCode } from 'mid-utils';
import { useContext, useEffect, useRef } from 'react';
import { ControlsWrapper, PreviewPlaceholder, ProductCustomizationFormWrapper } from '../BlocklyModule.styles';
import useFormResizeObserver from '../hooks/useFormResizeObserver';
import { CodeblocksWorkspaceType } from '../types';
import { FormWorkspaceControls } from './FormWorkspaceControls';
import { InputWorkspaceControls } from './InputWorkspaceControls';
import { useProductFormPreview } from './useProductFormPreview';

const { productCustomizationFormWrapper } = productCustomizationTestIds;

interface ProductFormPreviewProps {
  getCode: (highlightBlocks?: boolean) => string;
  blocklyWorkspace: Blockly.WorkspaceSvg | undefined;
  updateFormEnabled: boolean;
  setUpdateFormEnabled: StateSetter<boolean>;
  selectedWorkspace: CodeblocksWorkspaceType;
}

export const ProductFormPreview: React.FC<ProductFormPreviewProps> = ({
  getCode,
  blocklyWorkspace,
  setUpdateFormEnabled,
  updateFormEnabled,
  selectedWorkspace,
}): JSX.Element => {
  const { currentProductDefinition } = useContext(DataContext);
  const { isMessageDialogOpen, dialogMessage, showMessageDialog, closeMessageDialog } = usePrintMessageDialog();
  const {
    handleUpdateForm,
    handleSetModelValues,
    handleResetToDefaults,
    handleGetModelValues,
    handleInputUpdate,
    inputsError,
    currentFormRules,
    isFormLoading,
  } = useProductFormPreview({
    getCode,
    blocklyWorkspace,
    showMessageDialog,
    setUpdateFormEnabled,
  });

  const formRef = useRef<HTMLDivElement>(null);
  const formWidth = useFormResizeObserver(formRef);

  // the error might come from both CodeRunner or
  // from the custom .error prop from the Product Definition Input or
  // some invalid value type in some input
  const hasInputsError =
    currentProductDefinition.inputs.some((input) => input.error) ||
    inputsError?.errors.some((error) => error.errorCode === ErrorCode.CodeRunnerError) ||
    inputsError?.errors.some((error) => !error.proposedValue);

  //Update blockly workspace when form width changes
  useEffect(() => {
    if (blocklyWorkspace) {
      Blockly.svgResize(blocklyWorkspace);
    }
  }, [blocklyWorkspace, formWidth]);

  return (
    <PreviewPlaceholder className="mid-bg-shadow">
      <ControlsWrapper>
        {selectedWorkspace === CodeblocksWorkspaceType.INPUT && (
          <InputWorkspaceControls
            handleUpdateForm={handleUpdateForm}
            handleSetModelValues={handleSetModelValues}
            handleResetToDefaults={handleResetToDefaults}
            handleGetModelValues={handleGetModelValues}
            updateFormEnabled={updateFormEnabled}
            hasInputsError={hasInputsError}
          />
        )}
        {selectedWorkspace === CodeblocksWorkspaceType.FORM && <FormWorkspaceControls handleUpdateForm={handleUpdateForm} />}
      </ControlsWrapper>
      <ProductCustomizationFormWrapper data-testid={productCustomizationFormWrapper}>
        <ProductCustomizationForm
          inputs={currentProductDefinition.inputs}
          inputsError={inputsError}
          handleInputUpdate={handleInputUpdate}
          formLayoutRules={currentFormRules}
          isFormLoading={isFormLoading}
        />
      </ProductCustomizationFormWrapper>
      <PrintMessageDialog isMessageDialogOpen={isMessageDialogOpen} closeMessageDialog={closeMessageDialog} isAddin>
        {dialogMessage}
      </PrintMessageDialog>
    </PreviewPlaceholder>
  );
};
