import React from 'react';
import { DropdownItem, Dropdown } from '@mid-react-common/addins';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { InputHeaderCurrentFilter, InputHeaderCurrentFilterKeys } from './useInputsHeader';
import testIds from '../../../../inventor.testids';

export interface InputsFilterProps {
  items: string[];
  inputHeaderCurrentFilter: InputHeaderCurrentFilter;
  setFilter: React.Dispatch<React.SetStateAction<InputHeaderCurrentFilter>>;
  isParameter: boolean;
}

const InputsFilter: React.FC<InputsFilterProps> = ({
  items,
  inputHeaderCurrentFilter,
  setFilter,
  isParameter,
}): JSX.Element => {
  const selectedItem = isParameter ? inputHeaderCurrentFilter.parameters : inputHeaderCurrentFilter.properties;

  const handleOnSelect = (changes: { selectedItem?: DropdownItem | null }) => {
    const selectedFilter = changes.selectedItem?.value.toString() || '';

    const newFilter = { ...inputHeaderCurrentFilter };
    newFilter[isParameter ? InputHeaderCurrentFilterKeys.parameters : InputHeaderCurrentFilterKeys.properties] =
      selectedFilter;

    setFilter(newFilter);
  };

  const mapDropdownElement = items.map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <Dropdown
      data-testid={testIds.inputsFilter}
      elements={mapDropdownElement}
      selectedItem={{ value: selectedItem, label: selectedItem }}
      controlIcon={FilterAltOutlinedIcon}
      onSelect={handleOnSelect}
    />
  );
};

export default InputsFilter;
