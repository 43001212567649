import { GridRowModel, GridSelectionModel } from '@mui/x-data-grid';
import DataContext from 'context/DataStore/Data.context';
import text from 'inventor.text.json';
import { ProductDefinition, getFullFolderNamedPath } from 'mid-addin-lib';
import {
  MIDEmptyState,
  ModalContext,
  NOTIFICATION_STATUSES,
  NotificationContext,
  TableEmptyStateWrapper,
  initialModalState,
  unsavedChangesWarningMessage,
} from '@mid-react-common/common';
import { DuplicateProductDefinitionError, EditProductDefinitionError, logError } from 'mid-utils';
import { useContext, useState } from 'react';
import { PRODUCT_DEFINITION_TABLE_ACTION, PRODUCT_DEFINITION_TABLE_ACTIONS } from './ProductDefinitionTable.utils';

interface UseProductDefinitionTableState {
  tableData: GridRowModel[];
  selectedProductDefinitionIds: string[];
  numberOfProductDefinitionsSelected: number;
  duplicateLoading: boolean;
  handleNewProductDefinitionClickWithConfirmation: () => void;
  handleEditProductDefinitionButtonClick: () => void;
  handleDuplicateProductDefinitionButtonClick: () => void;
  handleDeleteProductDefinitionsButtonClick: () => void;
  onProductDefinitionSelection: (selectionModel: GridSelectionModel) => void;
  renderEmptyState: () => JSX.Element;
}

interface UseProductDefinitionTableProps {
  productDefinitions: ProductDefinition[] | null;
  handleNewProductDefinition: () => void;
  handleEditProductDefinition: (productDefinition: ProductDefinition) => void;
  handleDeleteProductDefinitions: (productDefinitionIds: string[]) => Promise<void>;
  handleDuplicateProductDefinition: (productDefinition: ProductDefinition) => Promise<void>;
}

const useProductDefinitionTable = ({
  productDefinitions,
  handleNewProductDefinition,
  handleEditProductDefinition,
  handleDeleteProductDefinitions,
  handleDuplicateProductDefinition,
}: UseProductDefinitionTableProps): UseProductDefinitionTableState => {
  const [selectedProductDefinitionIds, setSelectedProductDefinitionIds] = useState<string[]>([]);
  const numberOfProductDefinitionsSelected = selectedProductDefinitionIds.length;
  const { productDefinitionHasUnsavedChanges } = useContext(DataContext);
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const getSelectedProductDefinition = (action: PRODUCT_DEFINITION_TABLE_ACTION): ProductDefinition | undefined => {
    const productDefinitionId = selectedProductDefinitionIds[0];
    const productDefinition = productDefinitions?.find((productDefinition) => productDefinition.id === productDefinitionId);

    if (!productDefinition) {
      switch (action) {
        case PRODUCT_DEFINITION_TABLE_ACTIONS.EDIT:
          throw new EditProductDefinitionError(text.cannotFindTheProductDefinition, {
            numberOfProductDefinitionsSelected,
            productDefinitionId,
          });
        case PRODUCT_DEFINITION_TABLE_ACTIONS.DUPLICATE:
          throw new DuplicateProductDefinitionError(text.cannotFindTheProductDefinition, {
            numberOfProductDefinitionsSelected,
            productDefinitionId,
          });
      }
    }

    return productDefinition;
  };

  const tableData: GridRowModel[] = productDefinitions
    ? productDefinitions.map((d) => ({
        productDefinitionName: d.name,
        numberOfInputs: d.inputs?.length,
        publishTo:
          d.releaseName && d.account.name && d.project.name
            ? `${d.account.name}/${d.project.name}/${getFullFolderNamedPath(d.folder)}`
            : text.productDefinitionTableColumnUnreleased,
        releaseName: d.releaseName,
        lastUpdated: new Date(d.lastUpdated),
        id: d.id,
      }))
    : [];

  const handleEditProductDefinitionButtonClick = (): void => {
    try {
      const productDefinition = getSelectedProductDefinition('edit');
      if (productDefinition) {
        handleEditProductDefinition(productDefinition);
      }
    } catch (err: unknown) {
      logError(err);
      if (err instanceof EditProductDefinitionError) {
        showNotification({
          message: err.message,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        showNotification({
          message: text.notificationEditProductDefinitionFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }
  };

  const handleDuplicateProductDefinitionButtonClick = async () => {
    try {
      setDuplicateLoading(true);
      const productDefinition = getSelectedProductDefinition('duplicate');
      if (productDefinition) {
        await handleDuplicateProductDefinition(productDefinition);
      }
    } catch (err: unknown) {
      logError(err);
      if (err instanceof DuplicateProductDefinitionError) {
        showNotification({
          message: err.message,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        showNotification({
          message: text.notificationDuplicateProductDefinitionFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    } finally {
      setDuplicateLoading(false);
    }
  };

  const handleDeleteProductDefinitionsButtonClick = (): void => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      title: text.confirmationTitle,
      message: text.productDefinitionDeleteConfirmation,
      isConfirmCallbackAsync: true,
      onConfirmCallback: () => {
        try {
          handleDeleteProductDefinitions(selectedProductDefinitionIds);
          setSelectedProductDefinitionIds([]);
        } catch (err) {
          logError(err);
          throw err;
        }
      },
    });
  };

  const handleNewProductDefinitionClickWithConfirmation = (): void => {
    if (productDefinitionHasUnsavedChanges) {
      setModalState({
        ...initialModalState,
        isOpen: true,
        title: text.unsavedChanges,
        message: unsavedChangesWarningMessage,
        onConfirmCallback: handleNewProductDefinition,
      });
    } else {
      handleNewProductDefinition();
    }
  };

  const onProductDefinitionSelection = (selectedProductDefinitions: GridSelectionModel) => {
    setSelectedProductDefinitionIds(selectedProductDefinitions.map((productDefinition) => productDefinition.toString()));
  };

  const renderEmptyState = () => (
    <TableEmptyStateWrapper>
      <MIDEmptyState title={text.EmptyStateNoProductDefinition} description={text.EmptyStatePleaseCreateProductDefinition} />
    </TableEmptyStateWrapper>
  );

  return {
    tableData,
    selectedProductDefinitionIds,
    numberOfProductDefinitionsSelected,
    duplicateLoading,
    handleNewProductDefinitionClickWithConfirmation,
    handleEditProductDefinitionButtonClick,
    handleDuplicateProductDefinitionButtonClick,
    handleDeleteProductDefinitionsButtonClick,
    onProductDefinitionSelection,
    renderEmptyState,
  };
};

export default useProductDefinitionTable;
