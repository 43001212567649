import { DynamicContent } from '@adsk/offsite-dc-sdk';
import {
  PROJECT_FILES_TITLE,
  StateSetter,
  useCancellablePromise,
  useLogAndShowNotification,
} from '@mid-react-common/common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { difference, pull } from 'lodash';
import { GetAllProductsInProjectArgs, browserApiService, getAllProductsInProject } from 'mid-addin-lib';
import { getDcApiServiceInstance, getForgeApiServiceInstance } from 'mid-api-services';
import { FolderDMPermissionAction, ForgeDMProjectFolder, GetFoldersArgs, MetaInfo, ProjectFolder } from 'mid-types';
import { ProductError, logError } from 'mid-utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import text from '../../addins.text.json';
import { SELECTED_FOLDER_ID, SELECTED_PRODUCT_ID, SELECTED_PROJECT_ID } from '../../components/ACCDocSelection/constants';
import { TreeItem } from '../../components/MIDTree/MIDTree.types';
import { useAsyncFetchDataWithArgs } from '../http/hooks';

interface UseProductSelectionProps {
  selectedProjectId: string | undefined;
  permissionFilter?: FolderDMPermissionAction;
  onProjectChange?: () => void;
}
export interface UseProductSelectionState {
  rootFoldersTreeItems: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement: TreeItem | undefined;
  products: DynamicContent[] | null;
  handleDeleteProduct: (selectedProduct: DynamicContent) => void;
  deleteProductLoading: boolean;
  productsLoading: boolean;
  productsError: Error | null;
  handleFolderClick: (selectedElement: TreeItem) => void;
  handleNodeToggle: (nodeIds: string[], treeItems: TreeItem[]) => Promise<void>;
  handleProductClick: (contentId?: string) => void;
  selectedProduct: DynamicContent | undefined;
  setSelectedProduct: StateSetter<DynamicContent | undefined>;
  expandedFoldersIds: string[];
  setExpandedFoldersIds: StateSetter<string[]>;
}

export const useProductSelection = ({
  selectedProjectId,
  permissionFilter,
  onProjectChange,
}: UseProductSelectionProps): UseProductSelectionState => {
  const cancellablePromise = useCancellablePromise();
  const previousProjectId = useRef(sessionStorage.getItem(SELECTED_PROJECT_ID));

  // Folders
  const [getRootProjectFoldersQueryArgs, setRootProjectFoldersQueryArgs] = useState<GetFoldersArgs[] | undefined>();

  const getFolders = useCallback(
    (args: GetFoldersArgs) => cancellablePromise(getForgeApiServiceInstance().getFolders(args)),
    [cancellablePromise],
  );

  const {
    data: rootProductFolders,
    loading: rootFoldersLoading,
    error: rootFoldersError,
  } = useAsyncFetchDataWithArgs<ProjectFolder[]>(getFolders, getRootProjectFoldersQueryArgs);

  const [folderTreeError, setFolderTreeError] = useState<Error | null>(null);
  // auxiliary loader state to prevent flickering of the folders tree when different states are set asynchronously
  const [folderTreeLoading, setFolderTreeLoading] = useState(false);

  useLogAndShowNotification(rootFoldersError, text.notificationGetRootFolderFailed);

  const [rootFoldersTreeItems, setRootFoldersTreeItems] = useState<TreeItem[]>([]);
  const [selectedFolderTreeElement, setSelectedFolderTreeElement] = useState<TreeItem | undefined>();
  const [expandedFoldersIds, setExpandedFoldersIds] = useState<string[]>([]);

  // Products
  const [products, setProducts] = useState<DynamicContent[] | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<DynamicContent | undefined>();
  const [productsQueryArgs, setProductsQueryArgs] = useState<GetAllProductsInProjectArgs[] | undefined>();
  const {
    data: _fetchedProducts,
    loading: productsLoading,
    error: productsError,
  } = useAsyncFetchDataWithArgs<DynamicContent[]>(getAllProductsInProject, productsQueryArgs);
  const { enableMultiValuesBackwardsCompatibility } = useFlags();

  useLogAndShowNotification(productsError, text.notificationGetProductsFailed);

  const [deleteProductLoading, setDeleteProductLoading] = useState(false);

  const setExpandedTreeItems = async (projectId: string, folderUrn: string) => {
    setFolderTreeLoading(true);

    let projectFolders: ForgeDMProjectFolder[] = [];

    try {
      // returns the folder with sub-folders up to the folder, which 'folderUrn' argument is provided
      projectFolders = await getForgeApiServiceInstance().getFolderTree(projectId, folderUrn);
    } catch (error) {
      setFolderTreeError(error as Error);
    } finally {
      setFolderTreeLoading(false);
    }

    if (!projectFolders.length) {
      return;
    }

    let expandedPath: string[] = [];

    // extract the env-dependent prefix of the folderUrn (URN)
    const folderUrnPrefix = folderUrn.match(/.*\./)![0];

    let treeItemToSelect: TreeItem | undefined;

    // map to store the folder urn as a key and the full path to this folder as a value
    // how does the path look like: folderUrn1/folderUrn2/Folderurn3, it basically represents a hierarchy of folders
    const pathsMap = new Map<string, string>();

    // recursive conversion of the tree structure of a ForgeDMProjectFolder type to the TreeItem type
    function convertToTreeItems(projectFolders: ForgeDMProjectFolder[], treeItems: TreeItem[] = []): TreeItem[] {
      for (const projectFolder of projectFolders) {
        pathsMap.set(
          projectFolder.urn,
          projectFolder.path
            .split('/')
            // enhance path items with the urnPrefix
            .map((pathItem) => folderUrnPrefix + pathItem)
            .join('/'),
        );

        const treeItem: TreeItem = {
          id: projectFolder.urn,
          isExpandable: true,
          value: projectFolder.urn,
          label: projectFolder.title,
          children: projectFolder.folders.length ? convertToTreeItems(projectFolder.folders) : [],
          path: projectFolder.path
            .split('/')
            .map((pathItem): MetaInfo => ({ id: folderUrnPrefix + pathItem, name: projectFolder.title })),
        };

        treeItems.push(treeItem);

        // check if the currently processed folder is the one that has to be restored (folderUrn), if so, save the
        // expandedPath and save this treeItem for the selection
        if (projectFolder.path.split('/').at(-1) === folderUrn.replaceAll(folderUrnPrefix, '')) {
          expandedPath = projectFolder.path.split('/').map((item) => folderUrnPrefix + item);
          treeItemToSelect = treeItem;
        }
      }

      return treeItems;
    }
    const treeItems = convertToTreeItems(projectFolders);

    // if expandedPath is empty, it means that the Project Files is selected
    if (!expandedPath.length) {
      expandedPath = [treeItems[0].id.toString()];
    }

    // convert expandedPath to the same but with urn prefixes
    // edge case: when Project Files folder is not available, its urn will be in the expandedPath, but pathsMap would
    // not have any info about that. So it has to be filtered out.
    expandedPath = expandedPath
      .filter((expandedPathItem) => pathsMap.has(expandedPathItem))
      .map((expandedItem) => pathsMap.get(expandedItem)!);

    setRootFoldersTreeItems(treeItems);
    setExpandedFoldersIds(expandedPath);
    setSelectedFolderTreeElement(treeItemToSelect);
  };

  useEffect(() => {
    if (_fetchedProducts) {
      setProducts(_fetchedProducts);
    }
  }, [_fetchedProducts]);

  // remove selected folder & product from session storage when the project is changed
  useEffect(() => {
    const selectedProjectIdFromSessionStorage = sessionStorage.getItem(SELECTED_PROJECT_ID);
    if (selectedProjectIdFromSessionStorage === previousProjectId.current) {
      return;
    }
    sessionStorage.removeItem(SELECTED_FOLDER_ID);
    sessionStorage.removeItem(SELECTED_PRODUCT_ID);

    setSelectedProduct(undefined);
    setSelectedFolderTreeElement(undefined);
    setRootFoldersTreeItems([]);

    if (onProjectChange) {
      onProjectChange();
    }

    previousProjectId.current = selectedProjectIdFromSessionStorage;
  }, [selectedProjectId, onProjectChange]);

  useEffect(() => {
    async function fetchTokenAndSetProductQueryArgs() {
      const token = await browserApiService.getOAuth2Token();
      if (token && selectedProjectId) {
        setProductsQueryArgs([selectedProjectId, enableMultiValuesBackwardsCompatibility]);
      }
    }

    if (!selectedProjectId) {
      return;
    }

    const selectedFolderFromSessionStorage = sessionStorage.getItem(SELECTED_FOLDER_ID);

    // if the folder is available in session storage, we have to restore its expansion state, otherwise load the root
    if (selectedFolderFromSessionStorage && selectedProjectId) {
      setExpandedTreeItems(selectedProjectId, selectedFolderFromSessionStorage);
    } else {
      // Reset data & view when projectId changes
      setRootProjectFoldersQueryArgs(selectedProjectId ? [{ projectId: selectedProjectId, permissionFilter }] : undefined);
      setSelectedProduct(undefined);
      setSelectedFolderTreeElement(undefined);

      setFolderTreeLoading(true);
    }

    fetchTokenAndSetProductQueryArgs();
  }, [enableMultiValuesBackwardsCompatibility, permissionFilter, selectedProjectId]);

  useEffect(() => {
    if (!rootProductFolders || !selectedProjectId) {
      return;
    }

    const foldersTreeItems: TreeItem[] =
      rootProductFolders?.map((folder) => ({
        id: folder.urn,
        label: folder.title,
        value: folder.urn,
        isExpandable: true,
        children: [],
        path: [],
      })) || [];
    setRootFoldersTreeItems(foldersTreeItems);

    if (rootProductFolders.length && rootProductFolders[0].title === PROJECT_FILES_TITLE) {
      setExpandedTreeItems(selectedProjectId, rootProductFolders[0].urn);
    } else {
      // when there is no need to load expanded folders, the loader has to be reset
      setFolderTreeLoading(false);
    }
    // the dependencies list does not contain projectId, because there will be a call of useEffect where the projectId
    // has changed to the new project, but the new set of rootProductFolders is not yet loaded and represents the folders
    // from the old project. This will lead to exceptions in code.
    // projectId will be changed along with the rootProductFolders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootProductFolders]);

  const handleNodeToggle = async (nodeIds: string[], treeItems: TreeItem[]) => {
    // if the number of node ids coming from the TreeView is less than the number of currently expanded folders ids
    // it means that the user has collapsed some node. If that node has some expanded children and/or those expanded
    // children have expanded children too, we have to collapse them all, which means that we have to remove their
    // IDs from the 'nodeIds' array
    if (nodeIds.length < expandedFoldersIds.length) {
      // find the item that user has collapsed by getting a diff between the currently expanded nodes and what's coming
      // from the TreeView MUI component
      const collapsedItemId = difference(expandedFoldersIds, nodeIds).at(0)!;

      // variable to store the tree item that is about to be collapsed (which will be found by id)
      let itemToCollapse: TreeItem | undefined = undefined;

      // find the item in the tree structure (can be improved with a hash map if needed)
      const searchItemToCollapse = (item: TreeItem, itemIdToCollapse: string) => {
        if (item.id === itemIdToCollapse) {
          itemToCollapse = item;
          return;
        }

        if (item.children) {
          item.children.forEach((child) => searchItemToCollapse(child, itemIdToCollapse));
        }
      };

      // find all child nodes that has to be automatically collapsed along with the clicked node by the user
      const getAllChildrenToCollapse = (item: TreeItem, childrenToCollapse: string[] = []) => {
        item.children?.forEach((child) => {
          childrenToCollapse.push(child.id);
          getAllChildrenToCollapse(child, childrenToCollapse);
        });

        return childrenToCollapse;
      };

      // there always be the first node (Project Files), which is the starting point for the search
      searchItemToCollapse(treeItems.at(0)!, collapsedItemId);

      if (itemToCollapse) {
        const childrenToCollapse = getAllChildrenToCollapse(itemToCollapse);

        // remove all the children that have to be collapsed from the nodeIds
        nodeIds = pull(nodeIds, ...childrenToCollapse);
      }
    }

    // update the expanded nodes list
    setExpandedFoldersIds(nodeIds);
  };

  const handleFolderClick = (item: TreeItem) => {
    // skip project files, otherwise it will prevent other folders from expansion
    if (item.label === 'Project Files') {
      return;
    }

    setSelectedFolderTreeElement(item);

    sessionStorage.setItem(SELECTED_FOLDER_ID, item.id);
  };

  const handleProductClick = (contentId?: string) => {
    if (contentId) {
      const productClicked: DynamicContent | undefined = products?.find((product) => product.contentId === contentId);
      if (productClicked) {
        setSelectedProduct(productClicked);
        // persist selected product in session storage
        sessionStorage.setItem(SELECTED_PRODUCT_ID, productClicked.contentId);
      }
    }
  };

  // restore product selection from session storage
  useEffect(() => {
    if (!products?.length) {
      return;
    }

    const selectedProductIdFromSessionStorage = sessionStorage.getItem(SELECTED_PRODUCT_ID);

    if (selectedProductIdFromSessionStorage) {
      const productToSelect = products.find(({ contentId }) => contentId === selectedProductIdFromSessionStorage);

      if (productToSelect) {
        setSelectedProduct(productToSelect);
      }
    }
  }, [products]);

  const handleDeleteProduct = async (selectedProduct: DynamicContent) => {
    try {
      if (!selectedProduct?.contentId) {
        throw new ProductError(text.deleteProductErrorNoContentId, {
          currentProduct: selectedProduct,
        });
      }

      setDeleteProductLoading(true);
      await getDcApiServiceInstance().deleteProduct(selectedProduct.tenancyId, selectedProduct.contentId);
      const filteredProducts = products?.filter((product) => product.contentId !== selectedProduct.contentId);
      setProducts(filteredProducts || null);
    } catch (error: unknown) {
      logError(error);
      throw error;
    } finally {
      setDeleteProductLoading(false);
    }
  };

  // when the products failed to load (e.g. due to missing access), the loader has to be reset a well
  useEffect(() => {
    setFolderTreeLoading(false);
  }, [productsError]);

  return {
    products,
    productsLoading,
    productsError,
    rootFoldersTreeItems,
    rootFoldersLoading: rootFoldersLoading || folderTreeLoading,
    rootFoldersError: rootFoldersError || folderTreeError,
    selectedFolderTreeElement,
    handleFolderClick,
    handleNodeToggle,
    handleProductClick,
    handleDeleteProduct,
    deleteProductLoading,
    selectedProduct,
    setSelectedProduct,
    expandedFoldersIds,
    setExpandedFoldersIds,
  };
};

export default useProductSelection;
