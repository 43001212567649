import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { midTheme } from '@mid-react-common/common';

export const Wrapper = styled('div')`
  margin: auto;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.var.publishHeaderHeight}px`} - ${({ theme }) => `${theme.var.paddingBase * 2 * 2}px`}
  );
  overflow: auto;
  padding: ${({ theme }) => `0 ${theme.var.paddingBase * 4}px`};
`;

export const ProductDefinitionSummary = styled('div')`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`} 0;
`;

export const DividerWrapper = styled(Divider)`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`} 0;
  width: 50%;
`;

export const PublishTableContainer = styled('div')`
  border-radius: ${({ theme }) => `${theme.var.borderRadius}px`};
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const productFolderBrowserHeight =
  midTheme.var.headerHeight +
  midTheme.var.paddingBase * 2 * 2 +
  midTheme.var.releaseDetailsHeight +
  midTheme.var.publishLocationHeight +
  midTheme.var.marginBase * 2 +
  midTheme.var.productFolderBrowserGridHeaderHeight;
