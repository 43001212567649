import { useLogAndShowNotification } from '@mid-react-common/common';
import { useAsyncFetchDataWithArgs } from '@mid-react-common/addins';
import { checkAndGenerateThumbnailInBase64, createFullPath, getThumbnailImgPath } from 'mid-addin-lib';
import text from '../../../inventor.text.json';
import { useContext, useEffect, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';

interface UseThumbnailReturn {
  isThumbnailLoading: boolean;
  thumbnailInBase64: { name: string; base64: string } | null;
}

const useThumbnail = (): UseThumbnailReturn => {
  const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(true);
  const [fetchDependencyList, setFetchDependencyList] = useState<string[] | undefined>();
  const [thumbnailImgPathDependencyList, setThumbnailImgPathDependencyList] = useState<string[] | undefined>();
  const [localFullThumbnailPath, setLocalFullThumbnailPath] = useState<string>('');

  const { currentProductDefinition, setCurrentProductDefinitionThumbnail } = useContext(DataContext);
  const { thumbnail, topLevelFolder, assembly } = currentProductDefinition;

  /*
   * Fetch thumbnail path
   */
  const { data: thumbnailPath, error: thumbnailPathError } = useAsyncFetchDataWithArgs<string>(
    getThumbnailImgPath,
    thumbnailImgPathDependencyList,
  );
  useLogAndShowNotification(thumbnailPathError, text.notificationThumbnailFailed);

  useEffect(() => {
    if (!thumbnail && thumbnailPath) {
      setCurrentProductDefinitionThumbnail(thumbnailPath);
    }
  }, [thumbnailPath, setCurrentProductDefinitionThumbnail, thumbnail]);

  useEffect(() => {
    if (assembly && !localFullThumbnailPath) {
      // If top level is set, then assembly will be relative to top level
      // Otherwise, assembly will be an absolute path
      const fullPath = topLevelFolder ? createFullPath(topLevelFolder, assembly) : assembly;
      setLocalFullThumbnailPath(fullPath);
      setThumbnailImgPathDependencyList([fullPath]);
    }
  }, [topLevelFolder, assembly, localFullThumbnailPath]);

  /*
   * Fetch thumbnail in base64 from thumbnail path
   */
  const { data: thumbnailInBase64, error: thumbnailError } = useAsyncFetchDataWithArgs<{
    name: string;
    base64: string;
  }>(checkAndGenerateThumbnailInBase64, fetchDependencyList);

  useLogAndShowNotification(thumbnailError, text.notificationThumbnailFailed);

  useEffect(() => {
    if (thumbnail && localFullThumbnailPath) {
      setFetchDependencyList([thumbnail, localFullThumbnailPath]);
    }
  }, [thumbnail, localFullThumbnailPath]);

  useEffect(() => {
    if (thumbnailInBase64 || thumbnailError || thumbnailPathError) {
      setIsThumbnailLoading(false);
    }
  }, [thumbnailInBase64, thumbnailError, thumbnailPathError]);

  return {
    isThumbnailLoading,
    thumbnailInBase64,
  };
};

export default useThumbnail;
