import React, { useCallback, useContext } from 'react';
import Blockly from 'blockly';
import {
  importCodeBlocks,
  exportCodeBlocks,
  ImportCodeBlocksStatus,
  ProductDefinition,
  ExportCodeBlocksStatus,
} from 'mid-addin-lib';
import text from 'inventor.text.json';
import { adoptBlocks } from './transferBlocks.utils';
import { logError } from 'mid-utils';
import { NotificationContext } from '@mid-react-common/common';
import { initializeExportPlugin } from '../Plugins/ExportPlugin';
import { initializeImportPlugin } from '../Plugins/ImportPlugin';

interface useTransferBlocksProps {
  blocklyRef: React.MutableRefObject<Blockly.WorkspaceSvg | undefined>;
  currentProductDefinition: ProductDefinition;
}

interface useTransferBlocksReturn {
  initialize: (workspace: Blockly.WorkspaceSvg) => void;
}

const useTransferBlocks = ({ blocklyRef, currentProductDefinition }: useTransferBlocksProps): useTransferBlocksReturn => {
  const { showNotification } = useContext(NotificationContext);
  const handleImportBlocks = useCallback(
    async (blocklyWorkspace: Blockly.WorkspaceSvg) => {
      if (!blocklyRef.current) {
        return;
      }

      const importResult = await importCodeBlocks({
        fileLocation: currentProductDefinition.topLevelFolder,
        skipDialog: false,
      });

      if (importResult.status === ImportCodeBlocksStatus.success && importResult.codeBlocks) {
        try {
          const blocks = JSON.parse(importResult.codeBlocks);
          const newState = adoptBlocks(blocks, blocklyRef.current);

          Blockly.serialization.workspaces.load(newState, blocklyWorkspace);

          showNotification({
            message: text.importBlocksSuccessMessage,
            severity: 'success',
          });
        } catch (ex) {
          showNotification({ message: text.importBlocksFileParsingError, severity: 'error' });
          logError(ex);
        }
      }
    },
    [blocklyRef, currentProductDefinition.topLevelFolder, showNotification],
  );

  const handleExportBlocks = useCallback(
    async (serializedWorkspace: string) => {
      try {
        const exportResult = await exportCodeBlocks(serializedWorkspace, {
          fileName: `${currentProductDefinition.name}-canvas.idc`,
          fileLocation: currentProductDefinition.topLevelFolder,
          skipDialog: false,
        });

        if (exportResult.status === ExportCodeBlocksStatus.success) {
          showNotification({
            message: text.exportBlocksSuccessMessage,
            severity: 'success',
          });
        }

        if (exportResult.status === ExportCodeBlocksStatus.cancel) {
          showNotification({
            message: text.exportBlocksCancelledMessage,
            severity: 'info',
          });
        }

        if (exportResult.message) {
          logError(new Error(exportResult.message));
          showNotification({
            message: text.exportBlocksErrorMessage,
            severity: 'error',
          });
        }
      } catch (ex) {
        logError(ex);
      }
    },
    [currentProductDefinition.name, currentProductDefinition.topLevelFolder, showNotification],
  );

  const initialize = (workspace: Blockly.WorkspaceSvg) => {
    initializeExportPlugin(workspace, handleExportBlocks);
    initializeImportPlugin(workspace, handleImportBlocks);
  };

  return {
    initialize,
  };
};

export default useTransferBlocks;
