import text from 'inventor.text.json';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ProductDefinition,
  deleteProductDefinitions,
  getProductDefinitions,
  duplicateProductDefinition,
} from 'mid-addin-lib';
import { NOTIFICATION_STATUSES, NotificationContext, useLogAndShowNotification } from '@mid-react-common/common';
import { useAsyncFetchData } from '@mid-react-common/addins';
import { logError } from 'mid-utils';
import { useEffect, useContext } from 'react';
import DataContext from '../../context/DataStore/Data.context';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import {
  getMigratedProductDefinitionMultiValueInputs,
  hasSomeProductDefinitionsWithDeprecatedInputs,
} from './migrateProductDefinitionsContainingDeprecatedInputs.utils';
import TabProgressContext from 'context/TabProgressStore/TabProgress.context';
import { ampli } from '../../ampli';

interface UseProductDefinitionScreenProps {
  productDefinitions: ProductDefinition[] | null;
  loading: boolean;
  error: Error | null;
  handleEditProductDefinition: (productDefinition: ProductDefinition) => void;
  handleDeleteProductDefinitions: (productDefinitionIds: string[]) => Promise<void>;
  handleDuplicateProductDefinition: (productDefinition: ProductDefinition) => Promise<void>;
  handleNewProductDefinitionClick: () => void;
}

const useProductDefinitionSelectionScreen = (): UseProductDefinitionScreenProps => {
  const { enableMultiValuesBackwardsCompatibility } = useFlags();
  const { setCurrentScreen } = useContext(NavigationContext);
  const { resetToInitialActiveTab } = useContext(TabProgressContext);
  const {
    setCurrentProductDefinition,
    setAreDrawingFilesFetched,
    resetCurrentProductDefinition,
    setLatestWorkspaceSelected,
  } = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);
  const {
    data: productDefinitions,
    setData: setProductDefinitions,
    loading: productDefinitionsLoading,
    error,
  } = useAsyncFetchData<ProductDefinition[]>(getProductDefinitions);

  useLogAndShowNotification(error, text.notificationGetProductDefinitionsFailed);

  const handleEditProductDefinition = (productDefinition: ProductDefinition) => {
    resetToInitialActiveTab(); // Start at Source Content tab whenever we edit a product definition
    setCurrentProductDefinition(productDefinition);
    setAreDrawingFilesFetched(false);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
    setLatestWorkspaceSelected(undefined);
  };

  const handleDeleteProductDefinitions = async (productDefinitionIds: string[]): Promise<void> => {
    try {
      const currentProductDefinitions = await deleteProductDefinitions(productDefinitionIds, false);

      // Amplitude Event
      ampli.ivtwProductDefinitionDelete({
        numberOfDeletedProductDefinitions: productDefinitionIds.length,
        productDefinitionIds,
      });

      setProductDefinitions(currentProductDefinitions);
      showNotification({
        message: text.notificationDeleteProductDefinitionSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err) {
      showNotification({
        message: text.notificationDeleteProductDefinitionFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      logError(err);
      return Promise.reject(err);
    }
  };

  const handleDuplicateProductDefinition = async (productDefinition: ProductDefinition): Promise<void> => {
    const newProductDefinition = await duplicateProductDefinition({
      ...productDefinition,
      releaseName: '',
      latestContentId: undefined,
    });

    // Amplitude Event
    if (newProductDefinition.id) {
      ampli.ivtwProductDefinitionCreate({
        productDefinitionId: newProductDefinition.id,
        productDefinitionName: newProductDefinition.name,
        numberOfInputs: newProductDefinition.inputs.length,
        hasRules: newProductDefinition.rules.length > 0,
        hasCodeBlocksWorkspace: !!newProductDefinition.codeBlocksWorkspace,
        numberOfOutputs: newProductDefinition.outputs.length,
        numberOfDrawingThumbnails: newProductDefinition.drawingThumbnails?.length || 0,
        releaseName: newProductDefinition.releaseName,
        hasBeenDuplicated: true,
      });
    }

    const updatedProductDefinitionList = productDefinitions
      ? [newProductDefinition, ...productDefinitions]
      : [newProductDefinition];
    setProductDefinitions(updatedProductDefinitionList);
    showNotification({
      message: text.notificationDuplicateProductDefinitionSuccess,
      severity: NOTIFICATION_STATUSES.SUCCESS,
    });
  };

  // Should be used inside a `WithConfirmation` handler
  const handleNewProductDefinitionClick = () => {
    resetToInitialActiveTab();
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
    resetCurrentProductDefinition();
    setAreDrawingFilesFetched(false);
    setLatestWorkspaceSelected(undefined);
  };

  useEffect(() => {
    if (productDefinitions && enableMultiValuesBackwardsCompatibility) {
      if (hasSomeProductDefinitionsWithDeprecatedInputs(productDefinitions)) {
        const migratedProductDefinitions = productDefinitions.map((productDefinition) =>
          getMigratedProductDefinitionMultiValueInputs(productDefinition),
        );
        setProductDefinitions(migratedProductDefinitions);
      }
    }
  }, [productDefinitions, showNotification, enableMultiValuesBackwardsCompatibility, setProductDefinitions]);

  return {
    productDefinitions,
    loading: productDefinitionsLoading,
    error,
    handleNewProductDefinitionClick,
    handleEditProductDefinition,
    handleDeleteProductDefinitions,
    handleDuplicateProductDefinition,
  };
};

export default useProductDefinitionSelectionScreen;
