import text from '../../inventor.text.json';
import {
  getAllProductsInProject,
  PublishStatus,
  upsertProductDefinition,
  GetAllProductsInProjectArgs,
  ProductDefinitionPublishResult,
  publishProductDefinition,
  browserApiService,
  getFullFolderPath,
} from 'mid-addin-lib';
import {
  ModalContext,
  NOTIFICATION_STATUSES,
  NotificationContext,
  initialModalState,
  useLogAndShowNotification,
  useCancellablePromise,
} from '@mid-react-common/common';
import { TreeItem, useAsyncFetchDataWithArgs } from '@mid-react-common/addins';
import { useCallback, useContext, useEffect, useState } from 'react';
import DataContext from '../../context/DataStore/Data.context';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { updateModelAndProductDefinitionInputs } from './usePublishing.utils';
import { validateProductName } from '../../utils/productDefinition';
import { ampli } from '../../ampli';
import { DynamicContent } from '@adsk/offsite-dc-sdk';
import {
  FOLDERS_PERMISSION_FILTER_OPTIONS,
  FolderDMPermissionAction,
  GetFoldersArgs,
  ProjectFolder,
  MetaInfo,
  MetaInfoPath,
} from 'mid-types';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { isNumericInput } from 'mid-utils';
import { formRulesKey } from '../BlocklyModule/FormCodeblocks/FormCodeblocks.constants';

interface UsePublishingProps {
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
}
export interface UsePublishingState {
  products: DynamicContent[] | null;
  productsLoading: boolean;
  productsError: Error | null;
  rootFoldersTreeItems: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeItem: TreeItem | undefined;
  isPublishDisabled: boolean;
  productNameToPublish: string;
  handleSelectFolder: (item: TreeItem, path: MetaInfo[]) => void;
  handleNewProductDefinitionClick: () => void;
  handleOpenProductDefinitionsSelectionClick: () => void;
  handlePublishClick: () => Promise<void>;
  handleProductNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleProductNameDoubleClick: (product: DynamicContent) => void;
  publishResponse: ProductDefinitionPublishResult | undefined;
  publishFolderPermission: FolderDMPermissionAction;
  productNameErrors: string | null;
}

const publishFolderPermission = FOLDERS_PERMISSION_FILTER_OPTIONS.publish;

export const usePublishing = ({ selectedAccount, selectedProject }: UsePublishingProps): UsePublishingState => {
  const {
    currentProductDefinition,
    resetCurrentProductDefinition,
    setCurrentProductDefinitionPublishLocation,
    setCurrentProductDefinitionPublishStatus,
  } = useContext(DataContext);
  const { name, releaseName, latestContentId } = currentProductDefinition;
  const { setCurrentScreen } = useContext(NavigationContext);
  const { showNotification } = useContext(NotificationContext);
  const { setModalState } = useContext(ModalContext);
  const { enableMultiValuesBackwardsCompatibility } = useFlags();

  const [productNameToPublish, setProductNameToPublish] = useState(releaseName || name);
  const [rootFoldersTreeItems, setRootFoldersDropdownItems] = useState<TreeItem[]>([]);
  const [selectedFolderTreeItem, setSelectedFolderDropdownItem] = useState<TreeItem | undefined>();
  const [isPublishDisabled, setIsPublishDisabled] = useState(true);
  const [publishResponse, setPublishResponse] = useState<ProductDefinitionPublishResult | undefined>();
  const [productsQueryArgs, setProductsQueryArgs] = useState<GetAllProductsInProjectArgs[] | undefined>();

  const cancellablePromise = useCancellablePromise();

  const {
    data: products,
    loading: productsLoading,
    error: productsError,
  } = useAsyncFetchDataWithArgs<DynamicContent[]>(getAllProductsInProject, productsQueryArgs);

  useLogAndShowNotification(productsError, text.notificationGetProductsFailed);

  const [rootFoldersQueryArgs, setRootFoldersQueryArgs] = useState<GetFoldersArgs[] | undefined>();

  const getFolders = useCallback(
    (args: GetFoldersArgs) => cancellablePromise(getForgeApiServiceInstance().getFolders(args)),
    [cancellablePromise],
  );
  const {
    data: rootFolders,
    loading: rootFoldersLoading,
    error: rootFoldersError,
  } = useAsyncFetchDataWithArgs<ProjectFolder[], GetFoldersArgs>(getFolders, rootFoldersQueryArgs);

  useLogAndShowNotification(rootFoldersError, text.notificationGetRootFolderFailed);

  const handleUpdateDataStore = useCallback(
    (folder: MetaInfoPath) => {
      if (selectedAccount && selectedProject) {
        setCurrentProductDefinitionPublishLocation(selectedAccount, selectedProject, folder);
      }
    },
    [selectedAccount, selectedProject, setCurrentProductDefinitionPublishLocation],
  );

  useEffect(() => {
    setRootFoldersQueryArgs(
      selectedProject?.id ? [{ projectId: selectedProject?.id, permissionFilter: publishFolderPermission }] : undefined,
    );
    setSelectedFolderDropdownItem(undefined);
  }, [selectedProject?.id]);

  useEffect(() => {
    const foldersTreeDropdown: TreeItem[] =
      rootFolders?.map((folder) => ({
        id: folder.urn,
        value: folder.urn,
        label: folder.title,
        isExpandable: true,
        children: [],
        path: [],
      })) || [];
    setRootFoldersDropdownItems(foldersTreeDropdown);
  }, [rootFolders]);

  useEffect(() => {
    async function fetchTokenAndSetProductQueryArgs() {
      const token = await browserApiService.getOAuth2Token();
      if (token && selectedProject?.id) {
        setProductsQueryArgs([selectedProject?.id, enableMultiValuesBackwardsCompatibility]);
      }
    }
    fetchTokenAndSetProductQueryArgs();
  }, [enableMultiValuesBackwardsCompatibility, selectedProject?.id]);

  const productNameErrors = validateProductName(productNameToPublish);

  useEffect(() => {
    setIsPublishDisabled(!selectedFolderTreeItem || !productNameToPublish || productNameErrors !== null);
  }, [selectedFolderTreeItem, productNameToPublish, productNameErrors]);

  // wrapped with useCallback to prevent function re-creation and infinite loops for other standard react hooks which
  // depend on this function
  const handleSelectFolder = useCallback(
    (selectedFolder: TreeItem, path: MetaInfo[]) => {
      if (selectedFolder.id) {
        setSelectedFolderDropdownItem({
          id: selectedFolder.id,
          value: selectedFolder.id,
          label: selectedFolder.label,
          isExpandable: selectedFolder.isExpandable,
        });

        const bimFolder: MetaInfoPath = {
          id: selectedFolder.id,
          name: selectedFolder.label.toString(),
          parentPath: path,
        };
        handleUpdateDataStore(bimFolder);
      }
    },
    [setSelectedFolderDropdownItem, handleUpdateDataStore],
  );

  const handleNewProductDefinitionClick = () => {
    resetCurrentProductDefinition();
    setCurrentProductDefinitionPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
  };

  const handleOpenProductDefinitionsSelectionClick = () => {
    resetCurrentProductDefinition();
    setCurrentProductDefinitionPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_SELECTION);
  };

  const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductNameToPublish(event.target.value);
  };

  const createNewReleaseConfirmationModal = (currentUploadLocation: string) => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      title: text.publishNewReleaseConfirmationTitle,
      message: text.publishNewReleaseConfirmationMessage,
      onConfirmCallback: () => handlePublishProductDefinition(true, latestContentId, currentUploadLocation),
      confirmButtonLabel: text.publishButtonConfirmPublishing,
      cancelButtonLabel: text.buttonCancel,
    });
  };

  const createNewReleaseOrNewProductConfirmationModal = (currentUploadLocation: string) => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      title: text.publishProductNameHasChangedTitle,
      message: text.publishProductNameHasChangedMessage,
      onConfirmCallback: () => handlePublishProductDefinition(true, latestContentId, currentUploadLocation),
      confirmButtonLabel: text.publishButtonNewRelease,
      onSecondaryConfirmCallback: handlePublishProductDefinition,
      secondaryConfirmButtonLabel: text.publishButtonNewProduct,
      cancelButtonLabel: text.buttonCancel,
    });
  };

  const createNewReleaseWithDifferentProductDefinitionConfirmationModal = (
    productIdToCreateNewRelease: string,
    currentUploadLocation: string,
  ) => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      title: text.publishCreateNewReleaseForExistingProductTitle,
      message: text.publishCreateNewReleaseForExistingProductMessage,
      onConfirmCallback: () => handlePublishProductDefinition(true, productIdToCreateNewRelease, currentUploadLocation),
      confirmButtonLabel: text.publishButtonNewRelease,
      cancelButtonLabel: text.buttonCancel,
    });
  };

  const handlePublishClick = async () => {
    const productDefinitionAlreadyPublished = products?.find((product) => product.contentId === latestContentId);
    const productContainingSameProductNameToPublish = products?.find((product) => product.name === productNameToPublish);

    // Case : Product name remains unchanged
    // Actions : New release
    if (productDefinitionAlreadyPublished && productNameToPublish === releaseName) {
      const currentUploadLocation = productDefinitionAlreadyPublished.context.workspace.folderPath;
      return createNewReleaseConfirmationModal(currentUploadLocation);
    }

    // Case : Product name is changed to a new unpublished name
    // Actions :  New release or new product
    if (productDefinitionAlreadyPublished && !productContainingSameProductNameToPublish) {
      const currentUploadLocation = productDefinitionAlreadyPublished.context.workspace.folderPath;
      return createNewReleaseOrNewProductConfirmationModal(currentUploadLocation);
    }

    // Case : Product name is changed to an already published name
    // Actions : New release of that existing published product
    if (productContainingSameProductNameToPublish) {
      const currentUploadLocation = productContainingSameProductNameToPublish.context.workspace.folderPath;
      // and we pass in the contentId of that published product
      return createNewReleaseWithDifferentProductDefinitionConfirmationModal(
        productContainingSameProductNameToPublish.contentId,
        currentUploadLocation,
      );
    }

    // Otherwise, it is a totally new Product, so we POST new product
    handlePublishProductDefinition();
  };

  const handlePublishProductDefinition = async (
    isCreatingNewRelease = false,
    productIdToCreateNewRelease?: string,
    currentUploadLocation?: string,
  ): Promise<void> => {
    // If we are creating a new release,
    // We need to block the user from publishing to a new folder
    if (isCreatingNewRelease) {
      const newReleaseLocation = getFullFolderPath(currentProductDefinition.folder);
      if (newReleaseLocation !== currentUploadLocation) {
        showNotification({
          message: text.notificationPublishProductDefinitionSameFolder,
          severity: NOTIFICATION_STATUSES.WARNING,
        });
        return;
      }
    }
    setCurrentProductDefinitionPublishStatus(PublishStatus.LOADING);
    // We save the currentProductDefinition regardless of the outcome of publish
    const upsertedProductDefinition = await updateModelAndProductDefinitionInputs(currentProductDefinition);

    // Validate form rules before publishing
    if (upsertedProductDefinition.rules) {
      const formRules = upsertedProductDefinition.rules.find((rule) => formRulesKey === rule.key);
      if (formRules) {
        try {
          JSON.parse(formRules.code);
        } catch (e) {
          showNotification({
            message: text.notificationPublishProductDefinitionFailed,
            messageBody: text.publishFormRulesAreInvalid,
            severity: NOTIFICATION_STATUSES.ERROR,
          });
          setCurrentProductDefinitionPublishStatus(PublishStatus.FAILURE);
          return;
        }
      }
    }

    // We update the product inputs to exclude min/max/increment values, as the validation will be done in the front-end
    // Currently, the back-end validation clashes with the front-end validation.
    // In the future, we will have a validation based of the codeblocks rules in the back-end
    const inputs = upsertedProductDefinition.inputs.map((input) => {
      if (isNumericInput(input)) {
        const { min, max, increment, ...rest } = input;
        return rest;
      }
      return input;
    });

    const response = await publishProductDefinition(
      {
        ...upsertedProductDefinition,
        inputs,
        name: productNameToPublish,
        latestContentId: productIdToCreateNewRelease,
      },
      isCreatingNewRelease,
    );

    if (response.status.toLowerCase() === PublishStatus.COMPLETE) {
      const publishedProductDefinition = response.publishedProduct;

      // Amplitude Event
      if (publishedProductDefinition) {
        ampli.ivtwProductDefinitionPublish({
          projectId: publishedProductDefinition.tenancyId,
          productId: publishedProductDefinition.contentId,
          productName: publishedProductDefinition.name,
          numberOfInputs: publishedProductDefinition.inputs.length,
          hasRules: !!publishedProductDefinition.rulesKey,
          hasCodeBlocksWorkspace: !!publishedProductDefinition.codeBlocksWorkspace,
          releaseNumber: publishedProductDefinition.release,
          numberOfOutputs: publishedProductDefinition.outputs.length,
          outputTypes: publishedProductDefinition.outputs.map((output) => output.type),
          accountId: selectedAccount?.id,
        });
      }

      setCurrentProductDefinitionPublishStatus(PublishStatus.COMPLETE);
      showNotification({
        message: text.notificationPublishProductDefinitionSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
      // If success, we save the release name of the Product Definition as well
      await upsertProductDefinition({
        ...upsertedProductDefinition,
        releaseName: productNameToPublish,
        latestContentId: response.publishedProduct?.contentId,
      });
    } else if (response.status.toLowerCase() === PublishStatus.FAILURE) {
      showNotification({
        message: text.notificationPublishProductDefinitionFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      setCurrentProductDefinitionPublishStatus(PublishStatus.FAILURE);
    }

    setPublishResponse(response);
  };

  const handleProductNameDoubleClick = (product: DynamicContent) => {
    setProductNameToPublish(product.name);
  };

  return {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeItem,
    isPublishDisabled,
    publishResponse,
    productNameToPublish,
    handleSelectFolder,
    handleNewProductDefinitionClick,
    handleOpenProductDefinitionsSelectionClick,
    handlePublishClick,
    handleProductNameChange,
    handleProductNameDoubleClick,
    products,
    productsLoading,
    productsError,
    publishFolderPermission,
    productNameErrors,
  };
};

export default usePublishing;
