import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import text from 'inventor.text.json';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LeftSection, RightButton, RightSection, Title } from '@mid-react-common/common';
import React from 'react';

const Initial: React.FC<{
  handleManageProductsClick: () => void;
  handleMidWebPortalClick: () => void;
}> = ({ handleManageProductsClick, handleMidWebPortalClick }): JSX.Element => {
  const { enableTemplateManagement } = useFlags();

  return (
    <>
      <LeftSection>
        <Title variant="h1">{text.subHeaderTitleInitial}</Title>
      </LeftSection>
      <RightSection>
        {enableTemplateManagement && (
          <RightButton
            startIcon={<FolderCopyOutlinedIcon />}
            size="small"
            variant="outlined"
            onClick={handleManageProductsClick}
          >
            {text.manageProducts}
          </RightButton>
        )}
        <RightButton endIcon={<LaunchIcon />} onClick={handleMidWebPortalClick} size="small" variant="outlined">
          {text.buttonGoToMidWebPortal}
        </RightButton>
      </RightSection>
    </>
  );
};

export default Initial;
