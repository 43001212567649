import { useState } from 'react';
import { StateSetter } from '@mid-react-common/common';

export enum Screens {
  PRODUCT_DEFINITION_SELECTION = 'PRODUCT_DEFINITION_SELECTION',
  PRODUCT_DEFINITION_CONFIGURATION = 'PRODUCT_DEFINITION_CONFIGURATION',
  PUBLISHING = 'PUBLISHING',
  MANAGE_PRODUCTS = 'MANAGE_PRODUCTS',
}

export interface NavigationStore {
  currentScreen: Screens;
  setCurrentScreen: StateSetter<Screens>;
}

export const useNavigationStore = (): NavigationStore => {
  const [currentScreen, setCurrentScreen] = useState(Screens.PRODUCT_DEFINITION_SELECTION);

  return {
    currentScreen,
    setCurrentScreen,
  };
};
