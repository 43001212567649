import { DividerWrapper } from '@mid-react-common/common';
import ProductDefinitionName from './ProductDefinitionName/ProductDefinitionName';
import Drawings from './Drawings/Drawings';
import ModelForm from './ModelForm/ModelForm';
import PreviewThumbnail from './PreviewThumbnail/PreviewThumbnail';
import { DividerSection, GridWrapper, SourceContentPreviewWrapper } from './SourceContentTab.styles';
import useSourceContent from './useSourceContent';
import useThumbnail from './useThumbnail';

const SourceContentTab: React.FC = (): JSX.Element => {
  const {
    initialName,
    topLevelFolder,
    assemblyPath,
    inventorProjectPath,
    handleSelectInventorProjectClick,
    topLevelFolderOptions,
    handleTopLevelFolderChange,
  } = useSourceContent();
  const { thumbnailInBase64, isThumbnailLoading } = useThumbnail();

  return (
    <SourceContentPreviewWrapper>
      <ProductDefinitionName initialName={initialName} />
      <DividerWrapper />
      <GridWrapper>
        <ModelForm
          topLevelFolder={topLevelFolder}
          relativeAssemblyPath={assemblyPath}
          relativeInventorProjectPath={inventorProjectPath}
          handleSelectInventorProjectClick={handleSelectInventorProjectClick}
          topLevelFolderOptions={topLevelFolderOptions}
          handleTopLevelFolderChange={handleTopLevelFolderChange}
        />
        <DividerSection />
        <PreviewThumbnail thumbnailInBase64={thumbnailInBase64} isThumbnailLoading={isThumbnailLoading} />
      </GridWrapper>
      <DividerWrapper />
      <Drawings />
    </SourceContentPreviewWrapper>
  );
};

export default SourceContentTab;
