import { RightSection } from '@mid-react-common/common';
import React from 'react';
import InputsFilter from './InputsFilter';
import { InputsHeaderWrapper } from './InputsSelection.styles';
import { InputHeaderCurrentFilter } from './useInputsHeader';
// uncomment when bringing back the tabsЖ TRADES-5303
// import testIds from '../../../../inventor.testids';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import text from 'inventor.text.json';

export interface InputsHeaderProps {
  buttonGroupValue: string;
  handleOnParameterOriPropertyButtonChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
  currentFilter: InputHeaderCurrentFilter;
  setCurrentFilter: React.Dispatch<React.SetStateAction<InputHeaderCurrentFilter>>;
  filterItems: string[];
  totalSelected: number;
  isParameter: boolean;
}

const InputsHeader: React.FC<InputsHeaderProps> = ({
  // uncomment when bringing back the tabs: TRADES-5303
  // buttonGroupValue,
  // handleOnParameterOriPropertyButtonChange,
  currentFilter,
  setCurrentFilter,
  filterItems,
  isParameter,
}): JSX.Element => (
  <InputsHeaderWrapper>
    {/* Un-hide it after the MVS release */}
    {/*<ToggleButtonGroup*/}
    {/*  color="primary"*/}
    {/*  size="small"*/}
    {/*  value={buttonGroupValue}*/}
    {/*  exclusive*/}
    {/*  onChange={handleOnParameterOriPropertyButtonChange}*/}
    {/*  data-testid={testIds.parametersGroupButton}*/}
    {/*>*/}
    {/*  */}
    {/*  <ToggleButton value={text.inputsParameters} data-testid={testIds.parametersButton}>*/}
    {/*    {text.inputsParameters}*/}
    {/*  </ToggleButton>*/}
    {/*  */}
    {/*  <ToggleButton value={text.inputsProperties} data-testid={testIds.iPropertiesButton}>*/}
    {/*    {text.inputsProperties}*/}
    {/*  </ToggleButton>*/}
    {/*</ToggleButtonGroup>*/}
    <RightSection>
      <InputsFilter
        items={filterItems}
        inputHeaderCurrentFilter={currentFilter}
        isParameter={isParameter}
        setFilter={setCurrentFilter}
      />
    </RightSection>
  </InputsHeaderWrapper>
);

export default InputsHeader;
