import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { EmptyStateContainer, MIDEmptyState, midTheme as theme } from '@mid-react-common/common';
import CircularProgress from '@mui/material/CircularProgress';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { FolderDMPermissionAction, ProjectFolder } from 'mid-types';
import { ReactNode } from 'react';
import text from '../../addins.text.json';
import { TreeItem } from '../MIDTree/MIDTree.types';

// getChildNodes is a curried function
export const getChildNodes =
  (projectId: string, permissionFilter?: FolderDMPermissionAction) =>
  async ({ id }: TreeItem): Promise<TreeItem[]> =>
    await getSubFolderNodes(projectId, id, permissionFilter); // The id is the folder's URN

export const getSubFolderNodes = async (
  projectId: string,
  selectedFolderUrn: string,
  permissionFilter?: FolderDMPermissionAction,
): Promise<TreeItem[]> => {
  const subFolders: ProjectFolder[] = await getForgeApiServiceInstance().getSubFolders({
    projectId,
    folderUrn: selectedFolderUrn,
    permissionFilter,
  });
  const subFoldersDropdownItems: TreeItem[] = subFolders.map((folder) => ({
    id: folder.urn,
    label: folder.title,
    value: folder.urn,
    isExpandable: true,
    children: [],
    path: [],
  }));
  return subFoldersDropdownItems;
};

export const filterProductsByParentFolder = (
  products: DynamicContent[] | null,
  parentFolderUrn: string | undefined,
): DynamicContent[] | null => {
  let productsInSelectedFolder: null | DynamicContent[] = null;

  // Don't check for products.length here as
  // an array of size >= 0 is valid aka there can
  // be a folder with no products (represented by [])
  if (products && parentFolderUrn) {
    productsInSelectedFolder = products
      // we use endsWith as the last folder urn in the folderPath is the parent folder
      .filter((product) => product.context.workspace.folderPath.endsWith(parentFolderUrn));
  }
  return productsInSelectedFolder;
};

export const getEmptyFolderDataView = (
  projectId: string,
  rootFolders: TreeItem[],
  rootFoldersLoading: boolean,
  rootFoldersError: Error | null,
): ReactNode | null => {
  let emptyState: ReactNode | undefined;

  // No project selected takes precedence
  if (!projectId) {
    emptyState = <MIDEmptyState title={text.pleaseSelectProject} />;
  } else if (rootFoldersLoading) {
    emptyState = (
      <EmptyStateContainer>
        <CircularProgress size={theme.var.circularProgressSizeSmall} thickness={theme.var.circularProgressThickness} />
      </EmptyStateContainer>
    );
  } else if (rootFoldersError) {
    emptyState = <MIDEmptyState title={text.errorFetchingFolders} />;
  } else if (rootFolders.length === 0) {
    emptyState = <MIDEmptyState title={text.noFoldersInSelectedProject} />;
  }

  return emptyState ? <div className="mid-border-shadow">{emptyState}</div> : null;
};

export const getEmptyProductDataView = (
  productsLoading: boolean,
  productsError: Error | null,
  selectedFolderUrn: string | undefined,
  productsInSelectedFolder: DynamicContent[] | null,
): ReactNode | null => {
  let emptyState: ReactNode | undefined;
  // No folder selected takes precedence over other states
  if (!selectedFolderUrn) {
    emptyState = <MIDEmptyState title={text.pleaseSelectFolder} />;
  } else if (productsLoading) {
    emptyState = (
      <EmptyStateContainer>
        <CircularProgress size={theme.var.circularProgressSizeSmall} thickness={theme.var.circularProgressThickness} />
      </EmptyStateContainer>
    );
  } else if (productsError) {
    emptyState = <MIDEmptyState title={text.errorFetchingProducts} />;
  } else if (productsInSelectedFolder?.length === 0) {
    emptyState = <MIDEmptyState title={text.noProductsInSelectedFolder} />;
  }

  return emptyState ? <div className="mid-border-shadow">{emptyState}</div> : null;
};
