import Box from '@mui/material/Box/Box';
import { useState } from 'react';
import { StyledLink } from './ExpandableTextSection.styles';
import { urlRegex, urlReplacer } from './ExpandableTextSection.utils';
import { useTheme } from '@mui/material';
import domPurify from 'dompurify';
import commonText from '../../common.text.json';
import dataTestIds from '../../tests/helpers/dataTestIds';

const expandableTextSectionText = commonText.expandableTextSection;

export interface ExpandableTextSectionProps {
  content: string;
  textCutoffLength: number;
}

export const ExpandableTextSection: React.FC<ExpandableTextSectionProps> = ({ content, textCutoffLength }): JSX.Element => {
  const [textSectionExpanded, setTextSectionExpanded] = useState<boolean>(false);
  const theme = useTheme();
  const linkColor = theme.colors.primary.autodeskBlue[400];
  const sanitizedContent = domPurify.sanitize(content);
  const contentLargerThanCutoff = sanitizedContent.length > textCutoffLength;
  const truncatedContent = contentLargerThanCutoff
    ? textSectionExpanded
      ? sanitizedContent
      : `${sanitizedContent.slice(0, textCutoffLength)}...`
    : sanitizedContent;
  const modifiedContent: string = truncatedContent.replace(urlRegex, urlReplacer(linkColor));
  return (
    <Box>
      <Box
        data-testid={dataTestIds.expandableTextSection.mainContent}
        dangerouslySetInnerHTML={{ __html: modifiedContent }}
      ></Box>
      {contentLargerThanCutoff ? (
        <StyledLink
          onClick={() => setTextSectionExpanded((currentValue) => !currentValue)}
          className="mid-status-secondary"
          $linkColor={linkColor}
          data-testid={dataTestIds.expandableTextSection.expandCollapseContentButton}
        >
          {textSectionExpanded ? expandableTextSectionText.showLess : expandableTextSectionText.showMore}
        </StyledLink>
      ) : undefined}
    </Box>
  );
};
