import {
  NOTIFICATION_STATUSES,
  NotificationContext,
  PROJECT_FILES_TITLE,
  useCancellablePromise,
} from '@mid-react-common/common';
import { MetaInfo } from 'mid-types';
import { logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import text from '../../addins.text.json';
import { GetChildNodes, TreeItem as TreeItemType } from './MIDTree.types';

export interface UseMIDTreeProps {
  treeRootElements: TreeItemType[];
  onSelect: (item: TreeItemType, path: MetaInfo[]) => void;
  getChildNodes: GetChildNodes;
  newElementTreeToInject?: TreeItemType;
}

export interface UseMIDTreeState {
  items: TreeItemType[];
  onItemClick: (parent: TreeItemType) => Promise<void>;
}

const findParentElementTree = (treeRootElements: TreeItemType[], parentId: string) => {
  const stack = [...treeRootElements];
  while (stack.length) {
    const treeElement = stack.pop();
    if (treeElement) {
      if (treeElement.id === parentId) {
        return treeElement;
      }
      treeElement.children && stack.push(...treeElement.children);
    }
  }
  return null;
};

const useMIDTree = ({
  treeRootElements,
  onSelect,
  getChildNodes,
  newElementTreeToInject,
}: UseMIDTreeProps): UseMIDTreeState => {
  const { showNotification } = useContext(NotificationContext);
  const [items, setItems] = useState(treeRootElements);
  const cancellablePromise = useCancellablePromise();

  const setElementsTree = useCallback(
    (parentElement: TreeItemType, allChildNodes: TreeItemType[], isInjectingChildNode?: boolean) => {
      parentElement.children = isInjectingChildNode ? [...(parentElement.children || []), ...allChildNodes] : allChildNodes;
      parentElement.children.forEach((childItem) => {
        if (!childItem.path || !childItem.path.length) {
          childItem.path = [...(parentElement.path || []), { id: parentElement.id, name: parentElement.label.toString() }];
        }
      });

      setItems([...treeRootElements]);
    },
    [treeRootElements],
  );

  const onItemClick = useCallback(
    async (parent: TreeItemType) => {
      let allChildNodes: TreeItemType[];
      try {
        allChildNodes = await cancellablePromise(getChildNodes(parent));
      } catch (error: unknown) {
        logError(error);
        showNotification({
          message: text.cantGetChildElements,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
        return;
      }
      setElementsTree(parent, allChildNodes);

      onSelect(parent, parent.path || []);
    },
    [onSelect, getChildNodes, showNotification, setElementsTree, cancellablePromise],
  );

  useEffect(() => {
    if (newElementTreeToInject && newElementTreeToInject.parentId) {
      const parentElement = findParentElementTree(treeRootElements, newElementTreeToInject.parentId);

      if (parentElement) {
        setElementsTree(parentElement, [newElementTreeToInject], true);
      }
    }
  }, [newElementTreeToInject, setElementsTree, treeRootElements]);

  useEffect(() => {
    if (!treeRootElements.length) {
      return;
    }

    const rootElement = treeRootElements[0];

    // check if the first element is the Project Files, if yes, auto-expand it
    if (rootElement.label === PROJECT_FILES_TITLE) {
      if (!rootElement.children?.length) {
        return;
      }
      setElementsTree(rootElement, rootElement.children);

      onSelect(rootElement, rootElement.path || []);
    } else {
      setItems(treeRootElements);
    }
  }, [treeRootElements, setElementsTree, onSelect]);

  return {
    items,
    onItemClick,
  };
};

export default useMIDTree;
