import { midShelfCacheKey } from 'mid-utils';
import { inventorOutputTypes } from '../interfaces/inventorAutomation';
import { CachedFileInfo } from '../interfaces/localCache';
import { LogLevel } from '../interfaces/log';
import { mockProductDefinition3, mockProductDefinitions } from './data/mockProductDefinitions';
import { mockProperties, mockPropertiesInventor2 } from './data/mockParametersAndIProperties';
import { thumbnailsBase64 } from './data/mockThumbnailInBase64';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';
import { ENVIRONMENT, Environment, EnvironmentNoCy } from 'mid-types';
import { DialogStatus } from '../interfaces/fileSystem';
import { HostApi } from '../interfaces/hostApi';

export type AddInWindow = Window &
  typeof globalThis & {
    chrome: {
      webview: {
        hostObjects: {
          hostApi: HostApi;
        };
      };
    };
  };

const RESPONSE_DELAY = import.meta.env.VITE_CYPRESS_BUILD ? 0 : 1000;
const reactAppEnvironment = (import.meta.env.VITE_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
const reactAppEnvironmentCy = (import.meta.env.VITE_ENVIRONMENT as EnvironmentNoCy) || ENVIRONMENT.DEV;

export const mockHostApi: HostApi = {
  GetOAuth2Token: () => Promise.resolve('mocked-token'),

  LoadProductDefinitions: () => ({
    sync: () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve({ content: JSON.stringify(mockProductDefinitions), errorMessage: null });
        }, 1000);
      }),
  }),

  SaveProductDefinitions: (_mockProductDefinitions: string) => ({
    sync: () => Promise.resolve({ content: true, errorMessage: null }),
  }),

  GetThumbnailImage: (_mockDocumentPath: string) => ({
    sync: () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            content: 'https://via.placeholder.com/300/000000/FFFFFF/?text=Mocked+Thumbnail',
            errorMessage: null,
          });
        }, RESPONSE_DELAY);
      }),
  }),

  GetPartOrAssemblyProperties: (_mockPath: string) => ({
    sync: () =>
      new Promise((resolve) => {
        let properties = JSON.stringify(mockProperties);
        if (_mockPath === mockProductDefinition3.topLevelFolder + mockProductDefinition3.assembly) {
          properties = JSON.stringify(mockPropertiesInventor2);
        }
        setTimeout(() => {
          resolve({ content: properties, errorMessage: null });
        }, RESPONSE_DELAY);
      }),
  }),

  SelectFolder: (_mockFolder: string) => ({
    sync: () => Promise.resolve('C:/Workspace/Wall'),
  }),

  SelectFile: (_mockFolder: string, _mockfilter: string, _mockmultiSelect?: boolean) => ({
    sync: () => {
      if (_mockfilter.includes('*.ipj')) {
        return Promise.resolve(['C:\\path\\to\\folder\\project2\\project.ipj']);
      }
      return Promise.resolve(['C:/Workspace/Wall/Project1/Project2/Assembly.iam']);
    },
  }),

  GetEnvironment: () => ({
    sync: () => Promise.resolve(reactAppEnvironment),
  }),

  GetModelStates: (_mockDocumentPath: string) => ({
    sync: () => Promise.resolve({ content: ['Master', 'BIM'], errorMessage: null }),
  }),

  GetDcApiUrl: () => ({
    sync: () => Promise.resolve(`${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironmentCy].api}/`),
  }),

  GetMIDWebAppUrl: () => ({
    sync: () => Promise.resolve(`${ServiceConfigMap[ServiceTypes.MID_WEBAPP][reactAppEnvironmentCy].api}/`),
  }),

  FileToBase64String: (_mockFilePath: string) => ({
    sync: () => {
      const thumbnailsKeys = Object.keys(thumbnailsBase64) as (keyof typeof thumbnailsBase64)[];
      const randomIndex = Math.floor(Math.random() * thumbnailsKeys.length);
      return Promise.resolve({
        content: { name: 'fileName.txt', base64: thumbnailsBase64[thumbnailsKeys[randomIndex]] },
        errorMessage: null,
      });
    },
  }),

  CompressFolder: (_mockFolderPath: string) => ({
    sync: () =>
      Promise.resolve({
        content: 'path/to/zipfile',
        errorMessage: null,
      }),
  }),

  ExtractZipFileToFolder: (_mockZipFile: string, _mockTargetFolderPath: string) => ({
    sync: () =>
      Promise.resolve({
        content: 'path/to/folder',
        errorMessage: null,
      }),
  }),

  GenerateOutputs: (_mockTopFolder: string, _mockDocumentPath: string, _mockInputs: string, _mockModelState: string) => ({
    sync: () =>
      Promise.resolve({
        success: true,
        outputFiles: [
          {
            type: inventorOutputTypes.RFA,
            modelState: 'BIM',
            filePath: 'path/to/assembly [BIM].rfa',
          },
          {
            type: inventorOutputTypes.RFA,
            modelState: '[Primary]',
            filePath: 'path/to/assembly [Primary].rfa',
          },
          {
            type: inventorOutputTypes.THUMBNAIL,
            modelState: '',
            filePath: 'path/to/thumbnail.png',
          },
        ],
        report: '',
      }),
  }),

  insertRFA: (
    _mockTenancyId: string,
    _mockContentId: string,
    _mockVariantId: string,
    _mockRfaSignedUrl: string,
    _mockFamilyName: string,
    _mockCategory: string,
    _mockEngineVersion: string,
    _mockReleaseNumber: number,
    _mockModelState: string,
    _mockInputs: string,
  ) => Promise.resolve(),

  InsertRFA: (
    _mockTenancyId: string,
    _mockContentId: string,
    _mockVariantId: string,
    _mockRfaSignedUrl: string,
    _mockFamilyName: string,
    _mockCategory: string,
    _mockEngineVersion: string,
    _mockReleaseNumber: number,
    _mockModelState: string,
    _mockInputs: string,
  ) => Promise.resolve(),

  DownloadFileFromUrl: (_mockUrl: string) => ({
    sync: () => Promise.resolve({ content: 'mock/path/to/file', errorMessage: null }),
  }),

  DownloadFileFromUrlWithName: (_mockUrl: string, _mockFileName: string) => ({
    sync: () => Promise.resolve({ content: 'mock/path/to/file', errorMessage: null }),
  }),

  GetLocallyCached: (_mockKey: string) => ({
    sync: () => {
      if (_mockKey === midShelfCacheKey) {
        return Promise.resolve(JSON.stringify([]));
      }

      const fileInfo1: CachedFileInfo = {
        key: 'mock-key',
        type: 'RFA',
        filePath: 'mock/path/to/file.rfa',
        modelState: 'Primary',
        category: 'mocked-category',
        family: 'mocked-family',
      };
      const fileInfo2: CachedFileInfo = {
        key: 'mock-key-2',
        type: 'VARIANTINFO',
        filePath: 'mock/path/to/file.rfa',
        name: 'mock cached variant name',
        dataSetLocation: 'mock data set location',
        context: JSON.stringify({
          engine: {
            location: 'DA4I',
            version: '2023',
          },
        }),
      };
      return Promise.resolve(JSON.stringify([fileInfo1, fileInfo2]));
    },
  }),

  DownloadFileToLocalCache: (
    _mockKey: string,
    _mockSignedUrl: string,
    _mockName: string,
    _mockType: string,
    _mockMetaDataJson: string | null,
  ) => Promise.resolve(),

  WriteToCache: (_mockKey: string, _mockType: string, _mockMetaDataJson: string) => Promise.resolve(),

  GetAssemblyVersion: () => ({
    sync: () => Promise.resolve('Version info not available'),
  }),

  OpenExternalUrl: (_mockUrl: string) => ({
    sync: () => {
      window.location.href = _mockUrl;
      return Promise.resolve({ content: true, errorMessage: null });
    },
  }),

  DeleteFile: (_mockFilePath: string) => ({
    sync: () => Promise.resolve({ content: true, errorMessage: null }),
  }),

  OpenProductDefinitionDocument: (_mockDocumentPath: string, _mockInputs: string) => ({
    sync: () => Promise.resolve({ content: true, errorMessage: null }),
  }),

  IsDocumentOpenInTheEditor: (_mockDocumentPath: string) => ({
    sync: () => Promise.resolve({ content: true, errorMessage: null }),
  }),

  CheckAndGenerateThumbnailInBase64: (_mockFilePath: string, _mockDocumentPath: string) => ({
    sync: () => {
      const thumbnailsKeys = Object.keys(thumbnailsBase64) as (keyof typeof thumbnailsBase64)[];
      const randomIndex = Math.floor(Math.random() * thumbnailsKeys.length);
      return Promise.resolve({
        content: { name: 'fileName.txt', base64: thumbnailsBase64[thumbnailsKeys[randomIndex]] },
        errorMessage: null,
      });
    },
  }),

  GetApplicationVersionNumber: () => ({
    sync: () => Promise.resolve('2024'),
  }),

  SaveToFile: (_mockContent: string, _mockFileName: string, _mockFileExtension: string) => ({
    sync: () => Promise.resolve({ content: 'someFile', errorMessage: null }),
  }),

  SaveToFileUsingDialog: (
    _mockContent: string,
    _mockFileName: string,
    _mockLocation: string,
    _mockFileExtension: string,
    _mockTitle: string,
    _mockSkipDialog: boolean,
  ) => ({
    sync: () => Promise.resolve({ content: { status: DialogStatus.success }, errorMessage: null }),
  }),

  ReadFromFileUsingDialog: (
    _mockFileName: string,
    _mockLocation: string,
    _mockFileExtension: string,
    _mockTitle: string,
    _mockSkipDialog: boolean,
  ) => ({
    sync: () =>
      Promise.resolve({
        content: { status: DialogStatus.success, content: 'Test Content' },
        errorMessage: null,
      }),
  }),

  GetSelectedRFAInstance: () => ({
    sync: () =>
      Promise.resolve(
        JSON.stringify({
          tenancyId: 'mock-tenancy-id',
          contentId: 'mock-content-id',
          variantId: 'mock-variant-id',
          release: 1,
          modelState: 'mock-model-state',
        }),
      ),
  }),

  GetAllMIDInstancesData: () => ({
    sync: () => Promise.resolve('mock-mid-instances-data'),
  }),

  SelectAndEditMidInstance: (_mockElementId: string) => ({
    sync: () => Promise.resolve({ content: true, errorMessage: null }),
  }),

  editInstance: (
    _mockTenancyId: string,
    _mockContentId: string,
    _mockVariantId: string,
    _mockRfaSignedUrl: string,
    _mockFamilyName: string,
    _mockCategory: string,
    _mockEngineVersion: string,
    _mockReleaseNumber: number,
    _mockModelState: string,
    _mockInputs: string,
  ) => Promise.resolve(),

  EditInstance: (
    _mockTenancyId: string,
    _mockContentId: string,
    _mockVariantId: string,
    _mockRfaSignedUrl: string,
    _mockFamilyName: string,
    _mockCategory: string,
    _mockEngineVersion: string,
    _mockReleaseNumber: number,
    _mockModelState: string,
    _mockInputs: string,
  ) => Promise.resolve(),

  GetDrawingFiles: (mockFolder: string) => ({
    sync: () => {
      const defaultTopLevelFolder = `C:\\path\\to\\folder\\project2`;
      const topLevelFolder = defaultTopLevelFolder.startsWith(mockFolder) ? defaultTopLevelFolder : mockFolder;

      return Promise.resolve([
        `${topLevelFolder}\\Project1\\Project2\\Assembly.dwg`,
        `${topLevelFolder}\\Project1\\Project2\\Assembly2.dwg`,
        `${topLevelFolder}\\Project1\\Project2\\Assembly3.dwg`,
      ]);
    },
  }),

  LogToFile: (_mockMessage: string, _mockLevel: LogLevel) => Promise.resolve(),

  ResizeWindow: (_mockWidth: number, _mockHeight: number) => ({
    sync: () => Promise.resolve(),
  }),

  GetActiveDocumentInfo: () => ({
    sync: () =>
      Promise.resolve({
        content: { location: 'C:\\path\\to\\folder\\project2', name: 'assembly.iam' },
        errorMessage: null,
      }),
  }),

  WriteToPublishData: (_mockMetaDataJson: string) => Promise.resolve(),

  IsEditDialogOpen: () => ({
    sync: () => Promise.resolve({ content: true, errorMessage: null }),
  }),
};

export default mockHostApi;
