import { BrowserApiServiceResult } from '../interfaces/browserApi';
import { ProductDefinition } from '../interfaces/productDefinitions';
import browserApiService from '../services/browserApiService';
import {
  CreateProductDefinitionError,
  DeleteProductDefinitionError,
  UpdateProductDefinitionError,
  getUUID,
} from 'mid-utils';
import text from '../mid-addin-lib.text.json';
import { getActiveDocumentInfo } from './inventor';

export const getDuplicateProductDefinitionName = (name: string, productDefinitions: ProductDefinition[]): string => {
  let duplicatedName = `${name} (1)`;

  // regex if name has already a copy version like : name of product (1)
  const regex = /\(\d+\)$/;
  if (regex.test(name)) {
    // split the name and the copy version
    const extractNumberRegex = /\((\d+)\)$/;

    const [nameWithCopyVersion, copyVersion] = name.split(extractNumberRegex);
    const sanitizedName = nameWithCopyVersion.trim();
    // ... and increment by 1
    const incrementedVersion = Number(copyVersion) + 1;

    duplicatedName = `${sanitizedName} (${incrementedVersion})`;
  }

  const hasAlreadyThisCopyVersion = productDefinitions.some(
    (productDefinition) => productDefinition.name === duplicatedName,
  );
  // Check if user has already created another copy for this product definition
  if (hasAlreadyThisCopyVersion) {
    return getDuplicateProductDefinitionName(duplicatedName, productDefinitions);
  }

  return duplicatedName;
};

/**
 * get product definitions for active document
 * @param includeSoftDelete a flag for including soft-deleted definitions or not
 */
export const getProductDefinitions = async (includeSoftDelete = false): Promise<ProductDefinition[]> => {
  const productDefinitions = await getAllProductDefinitions(includeSoftDelete);
  return await getProductDefinitionsForActiveDocument(productDefinitions);
};

const getProductDefinitionsForActiveDocument = async (definitions: ProductDefinition[]) => {
  const activeDocumentInfo = await getActiveDocumentInfo();
  const activeDocumentDefinitions = definitions.filter((definition) => {
    const assemblyPath = definition.assembly.replaceAll('/', '\\');
    const index = assemblyPath.lastIndexOf('\\');
    const assemblyName = index >= 0 ? assemblyPath.substring(index + 1) : assemblyPath;
    // we only keep product definitions for the assemblies which are located in the same folder
    // So just compare the fileName is good enough, we don't need to compare the path any more
    return assemblyName.toLocaleLowerCase() === activeDocumentInfo.name.toLocaleLowerCase();
  });
  return activeDocumentDefinitions;
};

export const getAllProductDefinitions = async (includeSoftDelete: boolean): Promise<ProductDefinition[]> => {
  const loadedProductDefinitions: BrowserApiServiceResult<string> = await browserApiService.loadProductDefinitions();
  if (loadedProductDefinitions.value === null) {
    throw new Error(`${loadedProductDefinitions.errorMessage}`);
  }

  let definitions: ProductDefinition[] = loadedProductDefinitions.value.length
    ? JSON.parse(loadedProductDefinitions.value)
    : [];

  if (!includeSoftDelete) {
    definitions = definitions.filter((definition) => !definition.isArchived);
  }

  return definitions;
};

export const createProductDefinitions = async (definition: ProductDefinition): Promise<ProductDefinition> => {
  const productDefinitions: ProductDefinition[] = await getAllProductDefinitions(true);

  const newDefinition: ProductDefinition = {
    ...definition,
    id: getUUID(),
    lastUpdated: Date.now(),
  };

  productDefinitions.push(newDefinition);
  const definitionsJson = JSON.stringify(productDefinitions);
  const result: BrowserApiServiceResult<boolean> = await browserApiService.saveProductDefinitions(definitionsJson);

  if (!result.value) {
    throw new CreateProductDefinitionError(text.notificationSavedProductDefinitionFailed, {
      error: Error(result.errorMessage!),
    });
  }

  return newDefinition;
};

export const duplicateProductDefinition = async (definition: ProductDefinition): Promise<ProductDefinition> => {
  const productDefinitions: ProductDefinition[] = await getAllProductDefinitions(true);
  const productDefinitionToDuplicate: ProductDefinition = {
    ...definition,
    name: getDuplicateProductDefinitionName(definition.name, productDefinitions),
  };
  return createProductDefinitions(productDefinitionToDuplicate);
};

export const updateProductDefinition = async (definition: ProductDefinition): Promise<ProductDefinition> => {
  const productDefinitions: ProductDefinition[] = await getAllProductDefinitions(true);
  const index = productDefinitions.findIndex((x) => x.id === definition.id);
  if (index < 0) {
    throw new UpdateProductDefinitionError(text.notificationProductDefinitionNotExist, {});
  }

  const updatedDefinition: ProductDefinition = {
    ...definition,
    lastUpdated: Date.now(),
  };
  productDefinitions[index] = updatedDefinition;

  const definitionsJson = JSON.stringify(productDefinitions);
  const result: BrowserApiServiceResult<boolean> = await browserApiService.saveProductDefinitions(definitionsJson);

  if (!result.value) {
    throw new UpdateProductDefinitionError(text.notificationSavedProductDefinitionFailed, {
      error: Error(result.errorMessage!),
    });
  }

  return updatedDefinition;
};

export const upsertProductDefinition = async (definition: ProductDefinition): Promise<ProductDefinition> => {
  const productDefinitions: ProductDefinition[] = await getAllProductDefinitions(true);
  const index = productDefinitions.findIndex((x) => x.id === definition.id);

  if (index < 0) {
    return createProductDefinitions(definition);
  }
  return updateProductDefinition(definition);
};

export const deleteProductDefinitions = async (
  definitionIds: string[],
  softDelete: boolean,
): Promise<ProductDefinition[]> => {
  const productDefinitions: ProductDefinition[] = await getAllProductDefinitions(true);

  const restDefinitions = softDelete
    ? productDefinitions.map((definition) =>
        definition.id && definitionIds.includes(definition.id) ? { ...definition, isArchived: true } : definition,
      )
    : productDefinitions.filter((definition) => definition.id && !definitionIds.includes(definition.id));

  const definitionsJson = JSON.stringify(restDefinitions);

  const result: BrowserApiServiceResult<boolean> = await browserApiService.saveProductDefinitions(definitionsJson);

  if (!result.value) {
    throw new DeleteProductDefinitionError(text.notificationDeleteProductDefinitionsFailed, {
      error: Error(result.errorMessage!),
    });
  }

  return await getProductDefinitionsForActiveDocument(restDefinitions.filter((definition) => !definition.isArchived));
};
