import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { GridTwoColumns, Text } from '@mid-react-common/common';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FolderDMPermissionAction, MetaInfo } from 'mid-types';
import React from 'react';
import text from '../../addins.text.json';
import { productFolderBrowser as testIds } from '../../dataTestIds';
import MIDTree from '../MIDTree/MIDTree';
import { TreeItem } from '../MIDTree/MIDTree.types';
import {
  ListItemWrapper,
  ProductFolderBrowserContainer,
  ProductFolderFlexContainer,
  ProductListWrapper,
  TreeWrapper,
} from './ProductFolderBrowser.styles';
import { getChildNodes } from './ProductFolderBrowser.utils';
import { useProductFolderBrowser } from './useProductFolderBrowser';

export interface ProductFolderBrowserProps {
  projectId: string;
  rootFolders: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement?: TreeItem;
  products: DynamicContent[] | null;
  productsLoading: boolean;
  productsError: Error | null;
  selectedProductId?: string;
  displayCreateNewFolderButton?: boolean;
  maxHeight: number;
  onFolderClick: (element: TreeItem, path: MetaInfo[]) => void;
  onNodeToggle?: (nodeIds: string[], treeItems: TreeItem[]) => Promise<void>;
  onProductClick?: (contentId: string | undefined) => void;
  onProductDoubleClick?: (product: DynamicContent) => void;
  folderPermissionFilter?: FolderDMPermissionAction;
  expandedFoldersIds?: string[];
}

const ProductFolderBrowser: React.FC<ProductFolderBrowserProps> = ({
  projectId,
  rootFolders,
  rootFoldersLoading,
  rootFoldersError,
  selectedFolderTreeElement,
  displayCreateNewFolderButton,
  products,
  productsLoading,
  productsError,
  onFolderClick,
  onNodeToggle,
  onProductClick,
  onProductDoubleClick,
  folderPermissionFilter,
  maxHeight,
  selectedProductId,
  expandedFoldersIds,
}) => {
  const {
    emptyFolderDataView,
    emptyProductDataView,
    productsInSelectedFolder,
    handleProductClick,
    handleNewFolderClick,
    handleProductDoubleClick,
    newFolderTreeNodeCreated,
  } = useProductFolderBrowser({
    projectId,
    rootFolders,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeElement,
    products,
    productsLoading,
    productsError,
    onProductClick,
    onProductDoubleClick,
  });

  return (
    <ProductFolderBrowserContainer>
      <GridTwoColumns>
        <ProductFolderFlexContainer>
          <Text>{text.foldersInSelectedProject}</Text>
          {displayCreateNewFolderButton && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleNewFolderClick}
              disabled={!selectedFolderTreeElement}
              data-testid="button-new-folder"
            >
              {text.buttonNewFolder}
            </Button>
          )}
        </ProductFolderFlexContainer>
        <Text>{text.productsInSelectedFolder}</Text>
        {emptyFolderDataView || (
          <TreeWrapper maxHeight={maxHeight}>
            <MIDTree
              selectedFolderId={selectedFolderTreeElement?.id || ''}
              expandedFoldersIds={expandedFoldersIds}
              treeRootElements={rootFolders}
              onSelect={onFolderClick}
              onNodeToggle={onNodeToggle}
              getChildNodes={getChildNodes(projectId, folderPermissionFilter)}
              newElementTreeToInject={newFolderTreeNodeCreated}
            />
          </TreeWrapper>
        )}
        {emptyProductDataView || (
          <ProductListWrapper disablePadding className="mid-border-shadow" maxHeight={maxHeight}>
            {productsInSelectedFolder?.map((product) =>
              onProductClick || onProductDoubleClick ? (
                <ListItemButton
                  data-testid={testIds.productCell}
                  key={product.contentId}
                  selected={product.contentId === selectedProductId}
                  {...(onProductClick
                    ? { onClick: handleProductClick(product.contentId) }
                    : { onDoubleClick: handleProductDoubleClick(product) })}
                >
                  <ListItemIcon>
                    <PhotoOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>{product.name}</ListItemText>
                </ListItemButton>
              ) : (
                <ListItemWrapper data-testid={testIds.productCell} key={product.contentId}>
                  <ListItemIcon>
                    <PhotoOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>{product.name}</ListItemText>
                </ListItemWrapper>
              ),
            )}
          </ProductListWrapper>
        )}
      </GridTwoColumns>
    </ProductFolderBrowserContainer>
  );
};
export default ProductFolderBrowser;
