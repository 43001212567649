import {
  DCInput,
  InputType,
  OutputType,
  PostVariantInput,
  PostVariantOutput,
  PostVariantPayload,
} from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties } from '@mid-react-common/common';
import { FormLayoutGroup, FormRulesInputTypes } from 'mid-types';
import {
  getDCOutputModelStates,
  isDCRfaOutput,
  isFormRulesGroup,
  isNotDynamicContentIProperty,
  isValidNumber,
} from 'mid-utils';
import text from '../../addins.text.json';

export const transformToVariantPayloadRfaOnly = (
  configurableProductProperties: ConfigurableProductProperties,
  selectedRepresentation?: string,
): PostVariantPayload => {
  const inputPayload: PostVariantInput[] = configurableProductProperties.inputs.map((input: DCInput) => {
    const variantInput: PostVariantInput = {
      name: input.name,
      value: input.value,
    };

    return variantInput;
  });

  const outputPayload: PostVariantOutput[] = selectedRepresentation
    ? [{ type: OutputType.RFA, modelState: selectedRepresentation }]
    : configurableProductProperties.outputs.reduce<PostVariantOutput[]>((payload, output) => {
        if (isDCRfaOutput(output)) {
          const modelStates = getDCOutputModelStates(output) || [];
          return [...payload, ...modelStates.map((modelState) => ({ modelState, type: output.type }))];
        }
        return payload;
      }, []);

  const variantPayload: PostVariantPayload = {
    inputs: inputPayload,
    outputs: outputPayload,
  };

  return variantPayload;
};

export const getInputValue = (inputs: DCInput[], input: DCInput): string | number | boolean | undefined => {
  const foundInput = inputs.find((i) => i.name === input.name);

  return foundInput && foundInput.value;
};

export const updateInputValueInPlace = (inputs: DCInput[], payload: DCInput): DCInput[] =>
  inputs.map((input: DCInput) => {
    if (isNotDynamicContentIProperty(input) && input.type === payload.type && input.name === payload.name) {
      return payload;
    }

    return input;
  });

export const mapInputsToFormLayoutRules = (
  inputs: DCInput[],
  formRulesInputs: FormRulesInputTypes[] | undefined,
): (DCInput | FormLayoutGroup)[] => {
  if (formRulesInputs) {
    const updatedInputs = formRulesInputs.reduce<(DCInput | FormLayoutGroup)[]>((acc, input) => {
      const currentInput = inputs.find(
        (productDefinitionInput) => !isFormRulesGroup(input) && productDefinitionInput.name === input.name,
      );

      if (isFormRulesGroup(input)) {
        const groupInputs = input.inputs.reduce<DCInput[]>((groupAcc, groupInput) => {
          const groupCurrentInput = inputs.find((productDefinitionInput) => productDefinitionInput.name === groupInput.name);

          if (!groupCurrentInput) {
            return groupAcc;
          }

          groupAcc.push({
            ...groupCurrentInput,
            label: groupInput.label || groupCurrentInput.label,
          });
          return groupAcc;
        }, []);

        const group: FormLayoutGroup = {
          groupName: input.groupName,
          openByDefault: input.openByDefault,
          inputs: groupInputs,
        };

        acc.push(group);
        return acc;
      }

      if (!currentInput) {
        return acc;
      }
      acc.push({
        ...currentInput,
        label: currentInput.label || input.label,
      });
      return acc;
    }, []);
    return updatedInputs;
  }
  return inputs;
};

export const getInputLabel = (input: DCInput): string => {
  const readOnlyLabel = input.readOnly ? text.readOnlyInputText : '';
  if (input.type === InputType.NUMERIC) {
    return `${input.label || input.name} (${input.unit})${readOnlyLabel}`;
  }
  return `${input.label || input.name}${readOnlyLabel}`;
};
export const getValueRange = (input: DCInput): string => {
  let valueRange = '';
  if (input.type === InputType.NUMERIC) {
    const minDefined = isValidNumber(input.min);
    const maxDefined = isValidNumber(input.max);
    const incrementDefined = isValidNumber(input.increment);

    const minDefinedOnly = minDefined && !maxDefined;
    const maxDefinedOnly = maxDefined && !minDefined;
    const minMaxBothDefined = minDefined && maxDefined;

    if (minDefinedOnly) {
      valueRange += `min ${input.min}`;
    } else if (maxDefinedOnly) {
      valueRange += `max ${input.max}`;
    } else if (minMaxBothDefined) {
      valueRange += `${input.min} to ${input.max}`;
    }

    // increment doesn't make sense without defined min value
    if (minDefined && incrementDefined) {
      if (valueRange.length) {
        valueRange += ' - ';
      }

      valueRange += `increments of ${input.increment}`;
    }
  }
  return valueRange;
};
