import { DynamicContent } from '@adsk/offsite-dc-sdk';
import text from 'inventor.text.json';
import { MetaInfo } from 'mid-types';
import { initialModalState, ModalContext, NotificationContext, NOTIFICATION_STATUSES } from '@mid-react-common/common';
import { useBase64Thumbnail } from '@mid-react-common/addins';
import React, { useContext } from 'react';

interface UseProductDefinitionManagementScreenProps {
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
  selectedProduct: DynamicContent | undefined;
  setSelectedProduct: React.Dispatch<React.SetStateAction<DynamicContent | undefined>>;
  handleDeleteProduct: (selectedProduct: DynamicContent) => void;
}

export interface UseProductDefinitionManagementScreenState {
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
  handleDeleteProductDefinitionClick: () => void;
}

export const useProductDefinitionManagementScreen = ({
  selectedProduct,
  setSelectedProduct,
  handleDeleteProduct,
}: UseProductDefinitionManagementScreenProps): UseProductDefinitionManagementScreenState => {
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    selectedProduct?.tenancyId,
    selectedProduct?.thumbnail,
  );

  const _handleDeleteProductConfirmation = async () => {
    if (selectedProduct) {
      try {
        await handleDeleteProduct(selectedProduct);
        showNotification({
          message: text.deleteProductDefinitionSuccessMessage,
          severity: NOTIFICATION_STATUSES.SUCCESS,
        });
        setSelectedProduct(undefined);
      } catch (error: unknown) {
        showNotification({
          message: text.deleteProductDefinitionFailMessage,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }
  };

  const handleDeleteProductDefinitionClick = () => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      message: text.deleteProductDefinitionWarningConfirmQuestion,
      onConfirmCallback: _handleDeleteProductConfirmation,
    });
  };

  return {
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
    handleDeleteProductDefinitionClick,
  };
};

export default useProductDefinitionManagementScreen;
