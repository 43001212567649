import Blockly, { BlockSvg, Events, Block } from 'blockly';
import { blocklyDropdown } from '../constants';
import { ProductDefinitionInput, ProductDefinitionInputParameter } from 'mid-addin-lib';
import { getInputLabel } from '@mid-react-common/addins';
import { blocklyLabel, blocklyStatementInput, controlBlock, formContainerBlock } from './FormCodeblocks.constants';
import { BlocklyEvent } from '../types';
import { isCurrentSelectedOptionValid } from '../utils';

export const initializeFormBlocks = (workspace: Blockly.WorkspaceSvg, inputs: ProductDefinitionInput[]): void => {
  const formBlock = Blockly.serialization.blocks.append({ type: formContainerBlock }, workspace);
  const formBlockInput = formBlock.getInput(blocklyStatementInput);

  if (formBlock && formBlockInput !== null) {
    // create and connect all the inputs to the form block
    inputs
      .slice()
      .reverse()
      .forEach((input) => {
        const newBlock = Blockly.serialization.blocks.append({ type: controlBlock }, workspace);
        newBlock.getField(blocklyDropdown)?.setValue(input.name);
        newBlock.getField(blocklyLabel)?.setValue(getInputLabel(input));
        newBlock.previousConnection?.connect(formBlockInput.connection!);
        formBlockInput.connection?.connect(newBlock.outputConnection);
      });

    // Fire finished loading event
    Events.fire(new (Events.get(Events.FINISHED_LOADING))(workspace));
  }
};
export function labelValidator(block: BlockSvg, inputs: ProductDefinitionInputParameter[], newValue: string): void {
  const currentDropdownField = block.getField(blocklyDropdown);
  const currentLabelField = block.getField(blocklyLabel);
  const currentInput = inputs.find((input) => input.name === currentDropdownField?.getValue());

  if (currentInput && currentLabelField) {
    const defaultCurrentLabelValue = getInputLabel(currentInput);
    // Check if label is the default value
    if (defaultCurrentLabelValue === currentLabelField.getValue() || currentLabelField.getValue() === '') {
      const newInput = inputs.find((input) => input.name === newValue);
      if (newInput) {
        const newDefaultCurrentValue = getInputLabel(newInput);
        currentLabelField.setValue(newDefaultCurrentValue);
      }
    }
  }
}

export const disableOrphanedBlocks = (event: BlocklyEvent): void => {
  if (event.type === Blockly.Events.MOVE || event.type === Blockly.Events.CREATE) {
    if (!event.workspaceId) {
      return;
    }
    const eventWorkspace = Blockly.common.getWorkspaceById(event.workspaceId);
    if (!eventWorkspace) {
      return;
    }

    let block = eventWorkspace.getBlockById(event.blockId);
    if (!block) {
      return;
    }
    const parent = block.getRootBlock();

    if (parent && parent.type === formContainerBlock) {
      const children = block.getDescendants(false);
      children.forEach((child) => {
        const inputsTextValue = child.getField(blocklyDropdown)?.getText();
        if (inputsTextValue && !isCurrentSelectedOptionValid(inputsTextValue)) {
          child.setEnabled(false);
        } else {
          if (isBlockMarkedAsOrphaned(child)) {
            updateBlockOrphanedStatus(child, false);
            child.setEnabled(true);
          }
        }
      });
    } else if (block.outputConnection || block.previousConnection) {
      do {
        block.setEnabled(false);
        updateBlockOrphanedStatus(block, true);
        block = block.getNextBlock();
      } while (block);
    }
  }
};

export const updateBlockOrphanedStatus = (block: Block, orphanedStatus: boolean): void => {
  if (block.data) {
    const blockData = JSON.parse(block.data);
    block.data = JSON.stringify({ ...blockData, orphaned: orphanedStatus });
  } else {
    block.data = JSON.stringify({ orphaned: orphanedStatus });
  }
};
export const isBlockMarkedAsOrphaned = (block: Block): boolean => (block.data ? JSON.parse(block.data).orphaned : false);

export const addNewInputsToWorkspace = (
  recentlyAdoptedInputs: ProductDefinitionInput[],
  workspace: Blockly.WorkspaceSvg,
): void => {
  if (recentlyAdoptedInputs.length > 0) {
    // Get all the control blocks in the workspace
    const allControlBlocks = workspace.getAllBlocks(true)?.filter((block) => block.type === controlBlock);

    // Get all the inputs that are not already in the workspace
    // We do this, because there can be previously un-adopted inputs in the workspace that we don't want to add again
    const currentInputsInWorkspace = allControlBlocks?.map((block) => block.getField(blocklyDropdown)?.getValue());
    const inputsToAddInWorkspace = recentlyAdoptedInputs.filter((input) => !currentInputsInWorkspace?.includes(input.name));

    // Get the form block
    const topBlocks = workspace.getTopBlocks(true);
    const formBlock = topBlocks?.find((block) => block.type === formContainerBlock);

    if (formBlock) {
      const formBlockInput = formBlock.getInput(blocklyStatementInput);
      if (formBlockInput) {
        // Get first block in the form block and iterate through all the blocks until the last block
        let currentBlock = formBlockInput.connection?.targetBlock();
        while (currentBlock?.nextConnection?.targetBlock()) {
          currentBlock = currentBlock.nextConnection?.targetBlock();
        }

        // create and connect all the inputs to the form block
        inputsToAddInWorkspace.forEach((input) => {
          const newBlock = Blockly.serialization.blocks.append({ type: controlBlock }, workspace);
          newBlock.getField(blocklyDropdown)?.setValue(input.name);
          newBlock.getField(blocklyLabel)?.setValue(getInputLabel(input));
          newBlock.previousConnection?.connect(currentBlock!.nextConnection);
          currentBlock!.nextConnection?.connect(newBlock.previousConnection);
          currentBlock = newBlock;
        });
      }
    }
  }
};
