import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import DataContext from 'context/DataStore/Data.context';
import text from 'inventor.text.json';
import { Hyperlink, LeftSection, RightButton, RightSection, Title } from '@mid-react-common/common';
import React, { useContext } from 'react';

interface PublishLocationScreenHeaderProps {
  handleEditProductDefinitionClick: () => void;
  handleOpenProductDefinitionsSelectionClick: () => void;
}

export const PublishLocationScreenHeader: React.FC<PublishLocationScreenHeaderProps> = ({
  handleEditProductDefinitionClick,
  handleOpenProductDefinitionsSelectionClick,
}) => {
  const { currentProductDefinition } = useContext(DataContext);
  return (
    <>
      <LeftSection>
        <Title variant="h1">
          <Hyperlink component="button" onClick={handleOpenProductDefinitionsSelectionClick}>
            {text.buttonProductDefinitions}
          </Hyperlink>
          {' / '}
          {currentProductDefinition.name}
        </Title>
      </LeftSection>
      <RightSection>
        <RightButton
          onClick={handleEditProductDefinitionClick}
          size="small"
          variant="outlined"
          startIcon={<KeyboardBackspaceOutlinedIcon />}
        >
          {text.buttonEditProductDefinition}
        </RightButton>
      </RightSection>
    </>
  );
};
