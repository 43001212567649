import { AddinContainer, EnvironmentInfo } from '@mid-react-common/addins';
import {
  AppLoadingProgress,
  ConfirmationModal,
  ErrorBoundary,
  ErrorFallback,
  InventorWebComponentMoniker,
  ModalContext,
  NotificationsProvider,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  darkTheme,
  lightTheme,
  useModalStore,
  useNotificationStore,
} from '@mid-react-common/common';
import CssBaseline from '@mui/material/CssBaseline';
import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { browserApiService, getAuthTokenAndEnv } from 'mid-addin-lib';
import {
  InversifyTypes,
  inversifyContainer,
  registerAuthHandler,
  registerDcApiBaseURL,
  registerEnv,
  registerForgeApiBaseURL,
} from 'mid-api-services';
import { ServiceConfigMap, ServiceTypes, handleAmplitudeIdentify, setAmplitudeGroup } from 'mid-utils';
import React, { useEffect, useState } from 'react';
import { ampli } from './ampli';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import DataContext from './context/DataStore/Data.context';
import { useStore as useDataStore } from './context/DataStore/dataStore';
import NavigationContext from './context/NavigationStore/Navigation.context';
import { useNavigationStore } from './context/NavigationStore/navigationStore';
import TabProgressContext from './context/TabProgressStore/TabProgress.context';
import { useTabProgressStore } from './context/TabProgressStore/tabProgressStore';

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY_ADDINS || '',
  moniker: InventorWebComponentMoniker,
  appVersion: import.meta.env.VITE_IVTW_VERSION || 'Not provided',
});

const App: React.FC = (): JSX.Element => {
  const { enableMaintenanceMode, enableDarkModeSwitch } = useFlags();
  const [dark, setDark] = useState<boolean>(false);
  const dataStore = useDataStore();
  const navigationStore = useNavigationStore();
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();
  const { topLevelFolder, inventorProject, assembly, inputs, outputs, rules } = dataStore.currentProductDefinition;
  const tabProgressStore = useTabProgressStore({
    topLevelFolder,
    inventorProject,
    assembly,
    inputs,
    outputs,
    rules,
  });

  const [apiServicesInitialized, setApiServicesInitialized] = useState(false);

  useEffect(() => {
    const registerDependency = async () => {
      const { token, env } = await getAuthTokenAndEnv();

      const dcApiBaseURL = await browserApiService.getDcApiUrl();
      const forgeApiBaseUrl = ServiceConfigMap[ServiceTypes.FORGE_API][env];

      if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
        registerDcApiBaseURL(dcApiBaseURL);
      }

      if (!inversifyContainer.isBound(InversifyTypes.Env)) {
        registerEnv(env);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
        registerAuthHandler(() => Promise.resolve(token));
      }

      if (!inversifyContainer.isBound(InversifyTypes.ForgeApiBaseURL)) {
        registerForgeApiBaseURL(forgeApiBaseUrl.api);
      }

      setApiServicesInitialized(true);
    };

    registerDependency();
  }, []);

  const handleResetAppState = (): void => window.location.reload();

  const handleThemeChange = () => {
    setDark((state) => !state);
  };

  const appTree = (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />
      <ModalContext.Provider value={modalStore}>
        <ConfirmationModal isAddin />
        <AddinContainer>
          <NotificationsProvider store={notificationStore}>
            {apiServicesInitialized ? (
              <UserAnalyticsProvider
                handleAmplitudeIdentify={handleAmplitudeIdentify.bind(ampli)}
                setAmplitudeGroup={setAmplitudeGroup.bind(ampli)}
                webComponentMoniker={InventorWebComponentMoniker}
              >
                {enableMaintenanceMode ? (
                  <UnderMaintenance />
                ) : (
                  <NavigationContext.Provider value={navigationStore}>
                    <DataContext.Provider value={dataStore}>
                      <TabProgressContext.Provider value={tabProgressStore}>
                        {enableDarkModeSwitch && (
                          <Switch
                            onChange={handleThemeChange}
                            sx={{ position: 'absolute', top: 0, left: '50%', zIndex: 1000 }}
                          />
                        )}
                        <Header />
                        <EnvironmentInfo hostname={window.location.host} />
                        <ScreenManager />
                      </TabProgressContext.Provider>
                    </DataContext.Provider>
                  </NavigationContext.Provider>
                )}
              </UserAnalyticsProvider>
            ) : (
              <AppLoadingProgress />
            )}
          </NotificationsProvider>
        </AddinContainer>
      </ModalContext.Provider>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
