export const urlRegex =
  // eslint-disable-next-line max-len
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const urlReplacer =
  (linkColor: string) =>
  (matched: string): string => {
    let withProtocol = matched;

    if (!withProtocol.startsWith('http')) {
      withProtocol = 'http://' + matched;
    }

    // We need rel="noreferrer noopener"
    // to prevent the tabnabbing phishing attack
    const newStr = `<a target="_blank" rel="noreferrer noopener" href="${withProtocol}" style="color:${linkColor};">${matched}</a>`;
    return newStr;
  };
