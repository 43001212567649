import { FlexContainer } from '@mid-react-common/common';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleIcon from '@mui/icons-material/People';
import { useTheme } from '@mui/material/styles';
import { MetaInfo } from 'mid-types';
import React from 'react';
import text from '../../addins.text.json';
import Dropdown from '../../components/Dropdown/Dropdown';
import { DropdownItem } from '../../components/Dropdown/Dropdown.types';

interface ACCDocSelectionProps {
  maxHeight?: number;
  accounts: DropdownItem[];
  accountsLoading: boolean;
  projects: DropdownItem[];
  projectsLoading: boolean;
  selectedAccount?: MetaInfo;
  selectedProject?: MetaInfo;
  projectsDisabled: boolean;
  handleSelectAccount: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleSelectProject: (changes: { selectedItem?: DropdownItem | null }) => void;
}

export const ACCDocSelection: React.FC<ACCDocSelectionProps> = ({
  maxHeight,
  accounts,
  accountsLoading,
  projects,
  projectsLoading,
  selectedAccount,
  selectedProject,
  projectsDisabled,
  handleSelectAccount,
  handleSelectProject,
}): JSX.Element => {
  const theme = useTheme();
  return (
    <FlexContainer gap={theme.var.marginBase}>
      <Dropdown
        elements={accounts}
        isLoadingElements={accountsLoading}
        label={text.accountsSelectDropdown}
        selectedItem={
          selectedAccount
            ? {
                id: selectedAccount.id,
                value: selectedAccount.name,
                label: selectedAccount.name,
              }
            : null
        }
        onSelect={handleSelectAccount}
        menuItemIcon={PeopleIcon}
        width={`${theme.var.midTreeMinWidth}px`}
        maxHeight={maxHeight}
      />
      <Dropdown
        elements={projects}
        isLoadingElements={projectsLoading}
        label={text.projectsSelectDropdown}
        selectedItem={
          selectedProject
            ? {
                id: selectedProject.id,
                value: selectedProject.name,
                label: selectedProject.name,
              }
            : null
        }
        disabled={projectsDisabled}
        onSelect={handleSelectProject}
        menuItemIcon={LocationCityIcon}
        width={`${theme.var.midTreeMinWidth}px`}
        maxHeight={maxHeight}
      />
    </FlexContainer>
  );
};
