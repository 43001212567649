//When Inputs change, we need to add new dropdown options
import Blockly, { BlockSvg } from 'blockly';
import { blocklyDropdown } from '../constants';
import { labelValidator } from './FormCodeblocks.utils';
import { updateInputFieldDropdown } from '../utils';
import { getInputLabel } from '@mid-react-common/addins';
import { ProductDefinitionInputParameter } from 'mid-addin-lib';
import { CONTROL_BLOCK_EXTENSION, blocklyLabel } from './FormCodeblocks.constants';

export const initializeFormCodeblocksExtensions = (inputParameters: ProductDefinitionInputParameter[]): void => {
  if (Blockly.Extensions.isRegistered(CONTROL_BLOCK_EXTENSION)) {
    Blockly.Extensions.unregister(CONTROL_BLOCK_EXTENSION);
  }
  Blockly.Extensions.register(CONTROL_BLOCK_EXTENSION, function (this: BlockSvg) {
    const inputsDropdown = this.getInput(blocklyDropdown);
    const labelField = this.getField(blocklyLabel);
    if (inputsDropdown && labelField) {
      // Generate the input dropdown values
      const newInputsDropdown = new Blockly.FieldDropdown(() => [
        ...inputParameters.map((input) => [input.name, input.name]),
      ]);
      newInputsDropdown.setValidator((newValue: string) => labelValidator(this, inputParameters, newValue));
      updateInputFieldDropdown(this, newInputsDropdown, blocklyDropdown);

      // Set the first value as default label value
      if (inputParameters.length > 0) {
        labelField.setValue(getInputLabel(inputParameters[0]));
      }
    }
  });
};
