import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import text from 'inventor.text.json';
import { LeftSection, RightButton, RightSection, Title } from '@mid-react-common/common';
import React from 'react';

const ManageProducts: React.FC<{
  handleOpenProductDefinitionsSelectionClick: () => void;
}> = ({ handleOpenProductDefinitionsSelectionClick }): JSX.Element => (
  <>
    <LeftSection>
      <Title variant="h1">{text.manageProducts}</Title>
    </LeftSection>
    <RightSection>
      <RightButton
        onClick={handleOpenProductDefinitionsSelectionClick}
        size="small"
        variant="outlined"
        startIcon={<KeyboardBackspaceOutlinedIcon />}
      >
        {text.buttonEditProductDefinition}
      </RightButton>
    </RightSection>
  </>
);

export default ManageProducts;
