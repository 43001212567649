import { HigFolder, HigFolderOpen } from '@mid-react-common/common';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { MetaInfo } from 'mid-types';
import React from 'react';
import text from '../../addins.text.json';
import { StyledTreeView, TreeItemWrapper } from './MIDTree.styles';
import { GetChildNodes, TreeItem as TreeItemType } from './MIDTree.types';
import useMIDTree from './useMIDTree';

interface MIDTreeProps {
  treeRootElements: TreeItemType[];
  onSelect: (item: TreeItemType, path: MetaInfo[]) => void;
  onNodeToggle?: (nodeIds: string[], treeItems: TreeItemType[]) => Promise<void>;
  getChildNodes: GetChildNodes;
  newElementTreeToInject?: TreeItemType;
  selectedFolderId?: string;
  expandedFoldersIds?: string[];
}

const MIDTree: React.FC<MIDTreeProps> = ({
  treeRootElements,
  onSelect,
  getChildNodes,
  newElementTreeToInject,
  expandedFoldersIds,
  onNodeToggle,
  selectedFolderId,
}) => {
  const { items, onItemClick } = useMIDTree({
    treeRootElements,
    onSelect,
    getChildNodes,
    newElementTreeToInject,
  });

  const renderTree = (children: TreeItemType[]) =>
    children.map((child) => {
      const { id, label } = child;
      const childrenNodes = child?.children?.length ? renderTree(child?.children || []) : <></>;

      return (
        <TreeItemWrapper key={id} nodeId={id} label={label} onClick={onItemClick.bind(null, child)}>
          {childrenNodes}
        </TreeItemWrapper>
      );
    });

  return (
    <>
      <StyledTreeView
        selected={selectedFolderId}
        expanded={expandedFoldersIds?.map((selectedPath) => selectedPath.split('/').at(-1)!)}
        className="mid-border-shadow"
        {...(onNodeToggle
          ? {
              // override the standard onNodeToggle just to enhance it with the extra 'items' argument
              onNodeToggle: (_, nodeIds) => {
                onNodeToggle(nodeIds, items);
              },
            }
          : {})}
        aria-label={text.fileSystemNavigator}
        defaultCollapseIcon={
          <>
            <ArrowDropDown />
            <HigFolderOpen />
          </>
        }
        defaultExpandIcon={
          <>
            <ArrowRight />
            <HigFolder />
          </>
        }
      >
        {renderTree(items)}
      </StyledTreeView>
    </>
  );
};

export default MIDTree;
