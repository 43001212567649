import { GridSelectionModel } from '@mui/x-data-grid';
import text from 'inventor.text.json';
import {
  createFullPath,
  InventorProperties,
  ProductDefinitionInput,
  ProductDefinitionInputParameter,
  ProductDefinitionIProperty,
  toProductDefinitionInputParameter,
  toProductDefinitionIProperty,
} from 'mid-addin-lib';
import { useLogAndShowNotification } from '@mid-react-common/common';
import { useAsyncFetchDataWithArgs } from '@mid-react-common/addins';
import { useContext, useEffect, useState } from 'react';
import DataContext from '../../../../context/DataStore/Data.context';
import { fetchAllInventorData } from './utils';
import useParametersDefaults from './useParametersDefaults';
import { InputType } from '@adsk/offsite-dc-sdk';
import { isNotProductDefinitionIProperty, isProductDefinitionIProperty } from 'utils/typeGuards';

export type HandleSelectedInputUpdateType = (input: ProductDefinitionInput, property: { [key: string]: any }) => void;

export interface UseInputsSelectionState {
  inventorData: InventorProperties | null;
  productDefinitionInputs: ProductDefinitionInput[];
  selectedParameters: ProductDefinitionInputParameter[];
  selectedIProperties: ProductDefinitionIProperty[];
  setSelectedParameters: (names: GridSelectionModel) => void;
  setSelectedIProperties: (ids: GridSelectionModel) => void;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
  loading: boolean;
  error: Error | null;
}

const useInputsSelection = (): UseInputsSelectionState => {
  const {
    currentProductDefinition,
    updateCurrentProductDefinitionParameter,
    updateCurrentProductDefinitionIProperty,
    replaceAllCurrentProductDefinitionInputs,
    setRecentlyAdoptedInputs,
  } = useContext(DataContext);

  const [fetchDependencyList, setFetchDependencyList] = useState<string[] | undefined>();
  const [convertedInputs, setConvertedInputs] = useState<{
    parameters: ProductDefinitionInputParameter[];
    iProperties: ProductDefinitionIProperty[];
  }>({ parameters: [], iProperties: [] });

  const [localSelectedParameters, setLocalSelectedParameters] = useState<ProductDefinitionInputParameter[]>(
    currentProductDefinition.inputs.filter(isNotProductDefinitionIProperty),
  );
  const [localSelectedIProperties, setLocalSelectedIProperties] = useState<ProductDefinitionIProperty[]>(
    currentProductDefinition.inputs.filter(isProductDefinitionIProperty),
  );

  // Initial Setup, getting assembly from currentProductDefinition, which is needed to fetch Inventor Data
  useEffect(() => {
    const topLevelFolder = currentProductDefinition.topLevelFolder;
    const assembly = currentProductDefinition.assembly;

    if (topLevelFolder && assembly) {
      setFetchDependencyList([createFullPath(topLevelFolder, assembly)]);
    }
  }, [currentProductDefinition.topLevelFolder, currentProductDefinition.assembly]);
  // Initial Setup, getting assembly from currentProductDefinition, which is needed to fetch Inventor Data
  useEffect(() => {
    const topLevelFolder = currentProductDefinition.topLevelFolder;
    const assembly = currentProductDefinition.assembly;
    if (topLevelFolder && assembly) {
      setFetchDependencyList([createFullPath(topLevelFolder, assembly)]);
    }
  }, [currentProductDefinition.topLevelFolder, currentProductDefinition.assembly]);

  const {
    data: inventorData,
    loading,
    error,
  } = useAsyncFetchDataWithArgs<InventorProperties>(fetchAllInventorData, fetchDependencyList);

  useLogAndShowNotification(error, text.notificationGetPartOrAssemblyPropertiesFailed);

  useParametersDefaults({ inventorData });

  // Convert Inventor data to productDefinition interface
  useEffect(() => {
    if (inventorData) {
      const convertedParameters = inventorData.parameters.map((param) => toProductDefinitionInputParameter(param));
      const convertedIProperties = inventorData.iProperties.map((prop) => toProductDefinitionIProperty(prop));

      setConvertedInputs({ parameters: convertedParameters, iProperties: convertedIProperties });
    }
  }, [inventorData]);

  const setSelectedParameters = (names: GridSelectionModel) => {
    // New parameters being added
    const selectedParameters = convertedInputs.parameters.filter((input) => names.includes(input.name));
    setLocalSelectedParameters(selectedParameters);

    // Find all existing productDefinition inputs that intersect with the selected parameters or is an iproperty
    const existingProductDefinitionInputs = currentProductDefinition.inputs.filter(
      (oldInput) =>
        isProductDefinitionIProperty(oldInput) || selectedParameters.some((param) => param.name === oldInput.name),
    );

    // Find selected parameters that are not already in the existing productDefinition inputs
    const newInputs = selectedParameters.filter(
      (newInput) =>
        !existingProductDefinitionInputs.some(
          (existingInput) => isNotProductDefinitionIProperty(existingInput) && existingInput.name === newInput.name,
        ),
    );

    setRecentlyAdoptedInputs((prevState) => {
      const filterRemovedItems = selectedParameters.filter((input) => prevState.some((param) => param.name === input.name));
      return [...filterRemovedItems, ...newInputs];
    });

    replaceAllCurrentProductDefinitionInputs([...existingProductDefinitionInputs, ...newInputs]);
  };

  const setSelectedIProperties = (ids: GridSelectionModel) => {
    // New iproperties being added
    const selectedIProperties = convertedInputs.iProperties.filter((input) => ids.includes(input.id));
    setLocalSelectedIProperties(selectedIProperties);

    // Find all existing productDefinition inputs that intersect with the selected iproperties or is a parameter
    const existingProductDefinitionInputs = currentProductDefinition.inputs.filter(
      (oldInput) => isNotProductDefinitionIProperty(oldInput) || selectedIProperties.some((prop) => prop.id === oldInput.id),
    );

    // Find selected iproperties that are not already in the existing productDefinition inputs
    const newInputs = selectedIProperties.filter(
      (newInput) =>
        !existingProductDefinitionInputs.some(
          (existingInput) => isProductDefinitionIProperty(existingInput) && existingInput.id === newInput.id,
        ),
    );

    replaceAllCurrentProductDefinitionInputs([...existingProductDefinitionInputs, ...newInputs]);
  };

  const handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType = (input, property) => {
    if (input.type === InputType.IPROPERTY) {
      updateCurrentProductDefinitionIProperty(input, property);
    } else {
      updateCurrentProductDefinitionParameter(input, property);
    }
  };

  return {
    inventorData,
    productDefinitionInputs: currentProductDefinition.inputs,
    selectedParameters: localSelectedParameters,
    selectedIProperties: localSelectedIProperties,
    loading,
    error,
    setSelectedParameters,
    setSelectedIProperties,
    handleSelectedInputDataStoreUpdate,
  };
};

export default useInputsSelection;
