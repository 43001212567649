/* eslint-disable new-cap */
import { BrowserApi, BrowserApiServiceResult } from '../interfaces/browserApi';
import { HostApi, Result } from '../interfaces/hostApi';
import {
  Base64Info,
  DialogStatus,
  ReadFromFileUsingDialogResult,
  SaveToFileUsingDialogResult,
} from '../interfaces/fileSystem';
import { GenerateOutputsResult } from '../interfaces/inventorAutomation';
import { InventorProperties } from '../interfaces/inventorProperties';
import { CachedFileInfo } from '../interfaces/localCache';
import { Environment } from 'mid-types';
import { LogLevel } from '../interfaces/log';
import { InventorActiveDocumentInfo } from '../interfaces/inventorActiveDocumentInfo';
import text from '../mid-addin-lib.text.json';

declare global {
  interface Window {
    chrome: {
      webview: {
        hostObjects: {
          hostApi: HostApi;
        };
      };
    };
  }
}

class BrowserApiService implements BrowserApi {
  /**
   * Converts a webView2 proxy result into a TypeScript object.
   */
  getValueFromProxy(value: any, proxy: any): any {
    for (const property in value) {
      value[property] = proxy[property];
    }
    return value;
  }

  async getOAuth2Token(): Promise<string | null> {
    return await window.chrome?.webview?.hostObjects.hostApi.GetOAuth2Token();
  }

  async loadProductDefinitions(): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.LoadProductDefinitions().sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveProductDefinitions(productDefinitions: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .SaveProductDefinitions(productDefinitions)
      .sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getThumbnailImage(documentPath: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.GetThumbnailImage(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getPartOrAssemblyProperties(path: string): Promise<BrowserApiServiceResult<InventorProperties>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.GetPartOrAssemblyProperties(path).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const properties: InventorProperties = JSON.parse(result.content);
    return { value: properties, errorMessage: result.errorMessage };
  }

  async selectFolder(topFolder: string): Promise<string> {
    return await window.chrome?.webview?.hostObjects.hostApi.SelectFolder(topFolder).sync();
  }

  async selectFile(topFolder: string, filter: string, multiSelect: boolean): Promise<string[]> {
    return await window.chrome?.webview?.hostObjects.hostApi.SelectFile(topFolder, filter, multiSelect).sync();
  }

  async getEnvironment(): Promise<Environment> {
    return await window.chrome?.webview?.hostObjects.hostApi.GetEnvironment().sync();
  }

  async getModelStates(documentPath: string): Promise<BrowserApiServiceResult<string[]>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.GetModelStates(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getDcApiUrl(): Promise<string> {
    return await window.chrome?.webview?.hostObjects.hostApi.GetDcApiUrl().sync();
  }

  async getMIDWebAppUrl(): Promise<string> {
    return await window.chrome?.webview?.hostObjects.hostApi.GetMIDWebAppUrl().sync();
  }

  async fileToBase64String(filePath: string): Promise<BrowserApiServiceResult<Base64Info>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.FileToBase64String(filePath).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async compressFolder(folderPath: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.CompressFolder(folderPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async extractZipFileToFolder(zipFile: string, targetFolderPath: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .ExtractZipFileToFolder(zipFile, targetFolderPath)
      .sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async generateOutputs(
    topFolderPath: string,
    documentPath: string,
    inputs: string,
    requestedOutputs: string,
  ): Promise<GenerateOutputsResult> {
    const result = await window.chrome?.webview?.hostObjects.hostApi
      .GenerateOutputs(topFolderPath, documentPath, inputs, requestedOutputs)
      .sync();
    const outputsResult: GenerateOutputsResult = { success: result.success, report: result.report };
    if (result.outputFiles) {
      outputsResult.outputFiles = [];
      for (const proxy of result.outputFiles) {
        outputsResult.outputFiles.push(this.getValueFromProxy({ type: '', modelState: '', filePath: '' }, proxy));
      }
    }
    return outputsResult;
  }

  async insertRFA(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
    release: number,
    modelState: string,
    inputs: string,
  ): Promise<void> {
    const applicationVersion = await window.chrome?.webview?.hostObjects.hostApi.GetApplicationVersionNumber().sync();
    if (applicationVersion === text.revit2024) {
      // Needed because of a bug in the WebView2 version we use for Revit 2024 add-in.
      // The bug causes Revit to crash when C# method has Task as a return type and the
      // function name is camelCased.
      await window.chrome?.webview?.hostObjects.hostApi.insertRFA(
        tenancyId,
        contentId,
        variantId,
        rfaSignedUrl,
        familyName,
        category,
        engineVersion,
        release,
        modelState,
        inputs,
      );
    } else if (applicationVersion === text.revit2025) {
      await window.chrome?.webview?.hostObjects.hostApi.InsertRFA(
        tenancyId,
        contentId,
        variantId,
        rfaSignedUrl,
        familyName,
        category,
        engineVersion,
        release,
        modelState,
        inputs,
      );
    }
  }

  async downloadFileFromUrl(url: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.DownloadFileFromUrl(url).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async downloadFileFromUrlWithName(url: string, fileName: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .DownloadFileFromUrlWithName(url, fileName)
      .sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getLocallyCached(key: string): Promise<CachedFileInfo[]> {
    const resultString = await window.chrome?.webview?.hostObjects.hostApi.GetLocallyCached(key).sync();
    return JSON.parse(resultString);
  }

  async downloadFileToLocalCache(
    key: string,
    signedUrl: string,
    name: string,
    type: string,
    metaDataJson: string | null,
  ): Promise<void> {
    return await window.chrome?.webview?.hostObjects.hostApi.DownloadFileToLocalCache(
      key,
      signedUrl,
      name,
      type,
      metaDataJson,
    );
  }

  async writeToCache(key: string, type: string, metaDataJson: string): Promise<void> {
    return await window.chrome?.webview?.hostObjects.hostApi.WriteToCache(key, type, metaDataJson);
  }

  async getAssemblyVersion(): Promise<string> {
    return await window.chrome?.webview?.hostObjects.hostApi.GetAssemblyVersion().sync();
  }

  async openExternalUrl(url: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.OpenExternalUrl(url).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async deleteFile(filePath: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.DeleteFile(filePath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async openProductDefinitionDocument(documentPath: string, inputs: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .OpenProductDefinitionDocument(documentPath, inputs)
      .sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async isDocumentOpenInTheEditor(documentPath: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.IsDocumentOpenInTheEditor(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async checkAndGenerateThumbnailInBase64(
    filePath: string,
    documentPath: string,
  ): Promise<BrowserApiServiceResult<Base64Info>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .CheckAndGenerateThumbnailInBase64(filePath, documentPath)
      .sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async getApplicationVersionNumber(): Promise<string> {
    return await window.chrome?.webview?.hostObjects.hostApi.GetApplicationVersionNumber().sync();
  }

  async saveToFile(content: string, fileName: string, fileExtension: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .SaveToFile(content, fileName, fileExtension)
      .sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveToFileUsingDialog(
    content: string,
    fileName: string,
    fileLocation: string,
    filter: string,
    title: string,
    skipDialog: boolean,
  ): Promise<BrowserApiServiceResult<SaveToFileUsingDialogResult>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .SaveToFileUsingDialog(content, fileName, fileLocation, filter, title, skipDialog)
      .sync();
    if (!result.content && result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const saveToFileUsingDialogResult: SaveToFileUsingDialogResult = { status: DialogStatus.error };
    this.getValueFromProxy(saveToFileUsingDialogResult, result.content);
    return { value: saveToFileUsingDialogResult, errorMessage: result.errorMessage };
  }

  async readFromFileUsingDialog(
    fileName: string,
    fileLocation: string,
    filter: string,
    title: string,
    skipDialog: boolean,
  ): Promise<BrowserApiServiceResult<ReadFromFileUsingDialogResult>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .ReadFromFileUsingDialog(fileName, fileLocation, filter, title, skipDialog)
      .sync();
    if (!result.content && result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const readFromFileUsingDialogResult: ReadFromFileUsingDialogResult = {
      status: DialogStatus.error,
      content: '',
    };
    this.getValueFromProxy(readFromFileUsingDialogResult, result.content);
    return { value: readFromFileUsingDialogResult, errorMessage: result.errorMessage };
  }

  async getSelectedRFAInstance(): Promise<string> {
    const result = await window.chrome?.webview?.hostObjects.hostApi.GetSelectedRFAInstance().sync();
    return result;
  }

  async getAllMIDInstancesData(): Promise<string> {
    const result = await window.chrome?.webview?.hostObjects.hostApi.GetAllMIDInstancesData().sync();
    return result;
  }

  async selectAndEditMidInstance(elementId: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.SelectAndEditMidInstance(elementId).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async editInstance(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
    release: number,
    modelState: string,
    inputs: string,
  ): Promise<void> {
    const applicationVersion = await window.chrome?.webview?.hostObjects.hostApi.GetApplicationVersionNumber().sync();
    if (applicationVersion === text.revit2024) {
      // Needed because of a bug in the WebView2 version we use for Revit 2024 add-in.
      // The bug causes Revit to crash when C# method has Task as a return type and the
      // function name is camelCased.
      await window.chrome?.webview?.hostObjects.hostApi.editInstance(
        tenancyId,
        contentId,
        variantId,
        rfaSignedUrl,
        familyName,
        category,
        engineVersion,
        release,
        modelState,
        inputs,
      );
    } else if (applicationVersion === text.revit2025) {
      await window.chrome?.webview?.hostObjects.hostApi.EditInstance(
        tenancyId,
        contentId,
        variantId,
        rfaSignedUrl,
        familyName,
        category,
        engineVersion,
        release,
        modelState,
        inputs,
      );
    }
  }

  async getDrawingFiles(topFolder: string): Promise<string[]> {
    return await window.chrome?.webview?.hostObjects.hostApi.GetDrawingFiles(topFolder).sync();
  }

  async logToFile(message: string, logLevel: LogLevel): Promise<void> {
    return await window.chrome?.webview?.hostObjects.hostApi.LogToFile(message, logLevel);
  }

  async resizeWindow(width: number, height: number): Promise<void> {
    return await window.chrome?.webview?.hostObjects.hostApi.ResizeWindow(width, height).sync();
  }

  async getActiveDocumentInfo(): Promise<BrowserApiServiceResult<InventorActiveDocumentInfo>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.GetActiveDocumentInfo().sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const inventorActiveDocumentInfo: InventorActiveDocumentInfo = { location: '', name: '' };
    this.getValueFromProxy(inventorActiveDocumentInfo, result.content);
    return { value: inventorActiveDocumentInfo, errorMessage: result.errorMessage };
  }

  async writeToPublishData(publishDataJson: string): Promise<void> {
    return await window.chrome?.webview?.hostObjects.hostApi.WriteToPublishData(publishDataJson);
  }

  async isEditDialogOpen(): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.IsEditDialogOpen().sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }
}

export default new BrowserApiService();
