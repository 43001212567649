import '@blockly/block-plus-minus';
import { WorkspaceSearch } from '@blockly/plugin-workspace-search';
import { Backpack } from '@blockly/workspace-backpack';
import { ZoomToFitControl } from '@blockly/zoom-to-fit';
import { useTheme } from '@mui/material/styles';
import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import text from 'inventor.text.json';
import { SerializedBlocklyWorkspaceState } from 'mid-addin-lib';
import { NOTIFICATION_STATUSES, NotificationContext, StateSetter } from '@mid-react-common/common';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';
import DCDInputs from '../Blocks/DCDInputs';
import { initializeBlocklyExtensions } from '../Blocks/extensions';
import '../Blocks/javascriptBlocks';
import { initializeBlocklyMutators } from '../Blocks/mutators';
import { initializeDuplicateConnectedPlugin } from '../Plugins/DuplicateConnectedPlugin';
import blocklyConfig, { darkTheme, lightTheme } from '../blocklyConfig';
import {
  BACKPACK_CHANGE,
  BLOCKLY_EVENTS_TO_UPDATE,
  blocklyToolboxInputsCategory,
  currentRuleKey,
  INFINITE_LOOP_TRAP_CODE,
} from '../constants';
import toolbox from '../toolbox';
import { BlocklyEvent } from '../types';
import { isNotProductDefinitionIProperty } from 'utils/typeGuards';
import useTransferBlocks from './useTransferBlocks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBlocksExtraState, handleWorkspaceSwitch } from '../utils';

//Initialize custom blocks definition
Blockly.defineBlocksWithJsonArray(DCDInputs);

interface useBlocklyModuleProps {
  initialState?: SerializedBlocklyWorkspaceState;
  shouldCleanupBlocks?: boolean;
  hidden?: boolean;
}

interface useBlocklyModuleReturn {
  ref: (node: HTMLDivElement) => void;
  getState: () => SerializedBlocklyWorkspaceState;
  getCode: (highlightBlocks?: boolean) => string;
  blocklyWorkspace?: Blockly.WorkspaceSvg;
  updateFormEnabled: boolean;
  setUpdateFormEnabled: StateSetter<boolean>;
  hidden?: boolean;
}

const useBlocklyModule = ({ initialState, shouldCleanupBlocks, hidden }: useBlocklyModuleProps): useBlocklyModuleReturn => {
  const theme = useTheme();

  const {
    currentProductDefinition,
    backpackContents,
    setBackpackContents,
    setCurrentProductDefinitionRule,
    setCurrentProductDefinitionCodeBlocksWorkspace,
  } = useContext(DataContext);

  const muiDarkPaletteMode = 'dark';
  const backpackRef = useRef<Backpack>();
  const blocklyRef = useRef<Blockly.WorkspaceSvg>();
  const [isBlocklyWorkspaceInitialized, setBlocklyWorkspaceInitialized] = useState(false);
  const [isInitialStateLoadStepDone, setInitialStateLoadStepDone] = useState(false);
  const [updateFormEnabled, setUpdateFormEnabled] = useState(false);

  const { showNotification } = useContext(NotificationContext);

  const { initialize: initializeTransferBlocks } = useTransferBlocks({ blocklyRef, currentProductDefinition });

  const { enableTransferBlocks } = useFlags();
  const [transferBlocksInitialized, setTransferBlocksInitialized] = useState(false);

  const isBlocksStateValid = useCallback(() => {
    const extraStateList = getBlocksExtraState(getState());
    const allStateHasInput = extraStateList.every((extraState) => {
      if (!extraState.inputsDropdown) {
        return true;
      }
      return currentProductDefinition.inputs.some((input) => {
        if (extraState.inputsDropdown) {
          return JSON.parse(extraState.inputsDropdown).name === input.name;
        }
      });
    });
    return allStateHasInput;
  }, [currentProductDefinition.inputs]);

  const handleBlockStateValidation = useCallback(() => {
    if (!isBlocksStateValid()) {
      showNotification({
        message: text.blocklyInputNotAdopted,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [isBlocksStateValid, showNotification]);

  //Update Data store
  const handleUpdateDataStore = useCallback(
    (event: BlocklyEvent): void => {
      if (BLOCKLY_EVENTS_TO_UPDATE.includes(event.type)) {
        // Save rule in dataStore
        setCurrentProductDefinitionRule({ key: currentRuleKey, code: getCode() });
        // Save Blockly Workspace state  in dataStore
        setCurrentProductDefinitionCodeBlocksWorkspace(getState());
        setUpdateFormEnabled(true);
      }

      // Update backpack
      if (event.type === BACKPACK_CHANGE && backpackRef.current) {
        setBackpackContents(backpackRef.current.getContents());
      }
    },
    [setBackpackContents, setCurrentProductDefinitionCodeBlocksWorkspace, setCurrentProductDefinitionRule],
  );

  //Check for initial state load
  const handleInitialStateLoad = useCallback(
    (event: BlocklyEvent): void => {
      // Don't save Blockly Workspace if it was initialized for the first time
      if (event.type === Blockly.Events.FINISHED_LOADING) {
        //...and we set Step to Done (true). We do not want to load it again after component mounted
        // It could happen when codeBlocksWorkspace is updated in dataStore
        setInitialStateLoadStepDone(true);

        handleBlockStateValidation();

        //Once the blocks are loaded, add update data store listener
        blocklyRef.current?.addChangeListener(handleUpdateDataStore);
      }
    },
    [setInitialStateLoadStepDone, handleUpdateDataStore, handleBlockStateValidation],
  );

  const initializeTransferBlocksPlugin = useCallback(() => {
    if (blocklyRef.current && !transferBlocksInitialized && enableTransferBlocks) {
      initializeTransferBlocks(blocklyRef.current);
      setTransferBlocksInitialized(true);
    }
  }, [blocklyRef, transferBlocksInitialized, enableTransferBlocks, initializeTransferBlocks]);

  // Initialize Blockly Workspace
  const ref = useCallback(
    (node: HTMLDivElement) => {
      // transfer blocks plugin initialization happens here in case the feature flaggs are settled after to the app load
      initializeTransferBlocksPlugin();

      if (node && !isBlocklyWorkspaceInitialized) {
        const parameters = currentProductDefinition.inputs.filter(isNotProductDefinitionIProperty);
        //Initialize custom mutators and extensions
        initializeBlocklyMutators();
        initializeBlocklyExtensions(parameters);

        const workspace = Blockly.inject(node, {
          ...blocklyConfig,
          toolbox,
        });

        initializeDuplicateConnectedPlugin();

        workspace.registerToolboxCategoryCallback(blocklyToolboxInputsCategory, () =>
          DCDInputs.map((block) => ({ kind: 'block', type: block.type })),
        );

        blocklyRef.current = workspace;

        // Initialize plugins
        const zoomToFit = new ZoomToFitControl(blocklyRef.current);
        zoomToFit.init();

        const workspaceSearch = new WorkspaceSearch(workspace);
        workspaceSearch.init();

        const newBackpack = new Backpack(blocklyRef.current);
        newBackpack.init();
        backpackRef.current = newBackpack;

        //Initialize Events listeners
        workspace.addChangeListener(handleInitialStateLoad);

        // Initialize Blockly Workspace and its Mutators, Extensions adn Events just once
        setBlocklyWorkspaceInitialized(true);
        return () => {
          //Clean up Blockly
          if (workspace) {
            workspace.dispose();
          }
        };
      }
    },
    [currentProductDefinition.inputs, handleInitialStateLoad, initializeTransferBlocksPlugin, isBlocklyWorkspaceInitialized],
  );

  // Load initial state, if provided
  useEffect(() => {
    // This side effect should run once Blockly workspace has been initialized
    if (isBlocklyWorkspaceInitialized) {
      // Once Blockly workspace has been initialized,
      // we check if we have initial state to load just once...
      if (!isInitialStateLoadStepDone && initialState && blocklyRef.current) {
        try {
          Blockly.serialization.workspaces.load(initialState, blocklyRef.current);
          if (shouldCleanupBlocks) {
            blocklyRef.current.cleanUp();
          }
          if (backpackRef.current) {
            backpackRef.current.setContents(backpackContents);
          }
        } catch (e) {
          // If initial state is invalid, start with an empty state
          showNotification({
            message: text.blocklyInvalidInitialState,
            severity: NOTIFICATION_STATUSES.ERROR,
          });
        }
      }
    }
  }, [
    backpackContents,
    initialState,
    isBlocklyWorkspaceInitialized,
    isInitialStateLoadStepDone,
    shouldCleanupBlocks,
    showNotification,
    theme.palette.mode,
  ]);

  // function to return current blockly state
  const getState = () => {
    if (blocklyRef.current) {
      return Blockly.serialization.workspaces.save(blocklyRef.current);
    }
    return {};
  };

  const setState = (state: SerializedBlocklyWorkspaceState) => {
    if (blocklyRef.current) {
      Blockly.serialization.workspaces.load(state, blocklyRef.current);
    }
  };

  // for e2e tests
  window.setBlocklyState = setState;

  // function to return current blockly code
  const getCode = (highlightBlocks?: boolean) => {
    if (highlightBlocks) {
      javascriptGenerator.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
      javascriptGenerator.addReservedWords('highlightBlock');
    } else {
      javascriptGenerator.STATEMENT_PREFIX = '';
    }

    // This generates code that will keep a counter of the number of loops
    // that have been executed. If the counter exceeds a certain value
    // an infinite loop is assumed and the execution is interrupted.
    javascriptGenerator.INFINITE_LOOP_TRAP = INFINITE_LOOP_TRAP_CODE;

    return javascriptGenerator.workspaceToCode(blocklyRef.current);
  };

  // Theme
  useEffect(() => {
    if (theme.palette.mode === muiDarkPaletteMode) {
      blocklyRef.current?.setTheme(darkTheme);
    } else {
      blocklyRef.current?.setTheme(lightTheme);
    }
  }, [theme.palette.mode]);

  useEffect(() => {
    handleWorkspaceSwitch(blocklyRef.current, hidden);
  }, [hidden]);

  return {
    ref,
    getState,
    getCode,
    // We make sure that reference has loaded all blocks before passing it
    blocklyWorkspace: isInitialStateLoadStepDone ? blocklyRef.current : undefined,
    updateFormEnabled,
    setUpdateFormEnabled,
  };
};
export default useBlocklyModule;
