import { useState, useContext } from 'react';
import Chip from '@mui/material/Chip';
import { DrawingThumbnail } from 'mid-types';
import DataContext from 'context/DataStore/Data.context';
import { TABS } from 'context/TabProgressStore/tabProgressStore';
import TabProgressContext from 'context/TabProgressStore/TabProgress.context';
import Typography from '@mui/material/Typography';
import { FlexContainer, RightSection } from '@mid-react-common/common';
import { DropdownItem } from '@mid-react-common/addins';
import text from 'inventor.text.json';
import { OutputGrid } from '../OutputsTab.styles';
import DrawingTable from './DrawingTable/DrawingTable';
import SettingsTable from './SettingsTable';
import Drawing2DSelectionPreview from './Drawing2DSelectionPreview';
import { getDrawingChipLabels } from './Drawing2D.utils';
import { drawingThumbnailsTestIds } from 'tests/helpers/dataTestIds';

const Drawing2DOutput: React.FC = (): JSX.Element => {
  const { currentProductDefinition } = useContext(DataContext);
  const { handleTabRedirect } = useContext(TabProgressContext);

  const { drawingThumbnails } = currentProductDefinition;
  const filteredCheckedDrawingThumbnails: DrawingThumbnail[] =
    drawingThumbnails?.filter((drawingThumbnail: DrawingThumbnail): boolean => drawingThumbnail.checked) || [];

  const chipLabels = getDrawingChipLabels(filteredCheckedDrawingThumbnails);

  const drawingThumbnailsDropdownElements: DropdownItem[] = filteredCheckedDrawingThumbnails.map(
    ({ relativePath }): DropdownItem => ({
      value: relativePath,
      label: relativePath,
    }),
  );

  const [selectedDrawingDropdownElement, setSelectedDrawingDropdownElement] = useState<DropdownItem | null>(
    drawingThumbnailsDropdownElements.length ? drawingThumbnailsDropdownElements[0] : null,
  );
  const [selectedDrawingThumbnailPreview, setSelectedDrawingThumbnailPreview] = useState<DrawingThumbnail | undefined>(
    filteredCheckedDrawingThumbnails && filteredCheckedDrawingThumbnails[0],
  );

  const handleDrawingThumbnailSelectionPreview = (item: { selectedItem?: DropdownItem | null }): void => {
    if (item.selectedItem) {
      setSelectedDrawingDropdownElement(item.selectedItem);
      const selectedDrawingThumbnailItem = drawingThumbnails?.find(
        (drawingThumbnail) => drawingThumbnail.relativePath === item.selectedItem?.value,
      );
      if (selectedDrawingThumbnailItem) {
        setSelectedDrawingThumbnailPreview(selectedDrawingThumbnailItem);
      }
    }
  };

  const handleTabRedirectToSourceContent = () => {
    handleTabRedirect(TABS.SOURCE_CONTENT);
  };

  return (
    <>
      <FlexContainer>
        <Typography variant="h2">{text.outputsDrawing2D}</Typography>
        <RightSection>
          {chipLabels.map((chipLabel) => (
            <Chip label={chipLabel} key={chipLabel} />
          ))}
        </RightSection>
      </FlexContainer>
      <OutputGrid data-testid={drawingThumbnailsTestIds.outputSection}>
        <Drawing2DSelectionPreview
          drawingThumbnailsDropdownElements={drawingThumbnailsDropdownElements}
          selectedDrawingDropdownElement={selectedDrawingDropdownElement}
          selectedDrawingThumbnailPreview={selectedDrawingThumbnailPreview}
          handleDrawingThumbnailSelectionPreview={handleDrawingThumbnailSelectionPreview}
          handleTabRedirectToSourceContent={handleTabRedirectToSourceContent}
        />
        <div>
          <DrawingTable
            showSelectedDrawings={!!filteredCheckedDrawingThumbnails.length}
            selectedDrawingThumbnailInDropdown={selectedDrawingThumbnailPreview}
          />
          <SettingsTable excludeIntellectualProperty={!!filteredCheckedDrawingThumbnails.length} />
        </div>
      </OutputGrid>
    </>
  );
};

export default Drawing2DOutput;
