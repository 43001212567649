import { useTheme } from '@mui/material/styles';
import testIds from 'inventor.testids';
import text from 'inventor.text.json';
import { Dropdown, DropdownItem } from '@mid-react-common/addins';
import { FlexContainer, TextFieldWrapper } from '@mid-react-common/common';
import React from 'react';

interface RevitClassificationSectionProps {
  revitFamilyCategoryList: DropdownItem[];
  selectedCategory: DropdownItem | null;
  revitFamily: string;
  handleOnSelectCategory: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleRevitFamilyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RevitClassificationSection: React.FC<RevitClassificationSectionProps> = ({
  revitFamilyCategoryList,
  selectedCategory,
  revitFamily,
  handleOnSelectCategory,
  handleRevitFamilyChange,
}) => {
  const theme = useTheme();
  return (
    <FlexContainer flexDirection="column" gap={theme.var.paddingBase * 2}>
      <div data-testid={testIds.revitCategoryList}>
        <Dropdown
          elements={revitFamilyCategoryList}
          label={text.revitFamilyCategoryMandatory}
          selectedItem={selectedCategory}
          onSelect={handleOnSelectCategory}
          width={`${theme.var.outputTabInputWidth}px`}
          responsive
        />
      </div>
      <TextFieldWrapper
        data-testid={testIds.revitFamilyName}
        size="small"
        placeholder={text.revitFamilyTextInputPlaceholder}
        label={text.revitFamilyLabel}
        type="text"
        value={revitFamily}
        onChange={handleRevitFamilyChange}
        required
        width={theme.var.outputTabInputWidth}
      />
    </FlexContainer>
  );
};

export default RevitClassificationSection;
