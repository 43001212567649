import { DCInput } from '@adsk/offsite-dc-sdk';
import { FieldsetRow } from '@mid-react-common/common';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from '@mui/material';
import { DCInputUIExtension } from 'mid-types';
import { ForgeValidationError, isBooleanInput, isDynamicContentIProperty, isNumericInput, isTextInput } from 'mid-utils';
import React from 'react';
import { productCustomizationTestIds } from '../../../dataTestIds';
import { getInputLabel, getValueRange } from '../ProductCustomization.utils';
import { BooleanInput } from './BooleanInput';
import { IPropertyInput } from './IPropertyInput';
import { NumericInput } from './NumericInput';
import { TextInput } from './TextInput';

interface ProductCustomizationFormInputsProps {
  currentInput: DCInputUIExtension<DCInput>;
  inputsError?: ForgeValidationError;
  inactive?: boolean;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
}

const { formRowItemTestIdPrefix } = productCustomizationTestIds;

export const ProductCustomizationFormInputs: React.FC<ProductCustomizationFormInputsProps> = ({
  currentInput,
  inputsError,
  inactive,
  handleInputUpdate,
  setIsFormDataValid,
}: ProductCustomizationFormInputsProps) => {
  const label = currentInput?.label || getInputLabel(currentInput);
  const valueRange = getValueRange(currentInput);
  currentInput = inactive ? { ...currentInput, readOnly: true } : currentInput;
  const inputError = inputsError?.errors.find((inputError) => inputError.field === currentInput.name);
  const hasError = inputError && !inputError?.proposedValue;
  const alignItemsProp = isBooleanInput(currentInput) ? 'center' : 'flex-start';
  return (
    currentInput.visible && (
      <FieldsetRow data-testid={`${formRowItemTestIdPrefix}${currentInput.name}`} alignItemsProp={alignItemsProp}>
        {isNumericInput(currentInput) && (
          <NumericInput
            input={currentInput}
            error={hasError}
            handleInputUpdate={handleInputUpdate}
            label={label}
            setIsFormDataValid={setIsFormDataValid}
            valueRange={valueRange}
          />
        )}
        {isBooleanInput(currentInput) && (
          <BooleanInput input={currentInput} error={hasError} label={label} handleInputUpdate={handleInputUpdate} />
        )}
        {isTextInput(currentInput) && (
          <TextInput input={currentInput} error={hasError} handleInputUpdate={handleInputUpdate} label={label} />
        )}
        {isDynamicContentIProperty(currentInput) && <IPropertyInput input={currentInput} label={label} />}
        {/* Show validation message */}
        {inputError && (
          <Tooltip title={inputError.detail}>
            <IconButton>
              <ErrorIcon color={hasError ? 'error' : 'info'} />
            </IconButton>
          </Tooltip>
        )}
        {/* Show custom message (info or error depending on the 'error' parameter) */}
        {!inputError && (currentInput.message || currentInput.error) && (
          <Tooltip title={currentInput.message}>
            <IconButton>{currentInput.error ? <ErrorIcon color="error" /> : <InfoIcon color="info" />}</IconButton>
          </Tooltip>
        )}
      </FieldsetRow>
    )
  );
};
