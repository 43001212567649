import { DividerWrapper } from '@mid-react-common/common';
import React from 'react';
import text from '../../../inventor.text.json';
import { OutputProps } from './Output.types';
import { OutputsWrapper } from './OutputsTab.styles';
import RevitClassificationSection from './RevitClassificationSection';
import { OutputType } from '@adsk/offsite-dc-sdk';
import Drawing2DOutput from './Drawing2D/Drawing2DOutput';
import Output from './Output';
import { GroupedOutputs } from './GroupedOutputs/GroupedOutputs';
import { useGetAvailableRepresentations } from './hooks/useGetAvailableRepresentations';
import { useRevitCategoryAndFamilySelector } from './hooks/useRevitCategoryAndFamilySelector';
import { useSelectOutputRepresentations } from './hooks/useSelectOutputRepresentations';

const OutputsTab: React.FC = (): JSX.Element => {
  const { availableRepresentations } = useGetAvailableRepresentations();
  const { revitFamilyCategory, localRevitFamily, revitFamilyCategoryList, handleOnSelectCategory, handleRevitFamilyChange } =
    useRevitCategoryAndFamilySelector();
  const { selectedOutputRepresentationsMap, handleRepresentationChange } = useSelectOutputRepresentations();

  const RFA: OutputProps = {
    outputType: OutputType.RFA,
    title: text.outputsRevitFamily,
    acronyms: [text.outputsRFA],
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.RFA) || [],
    handleRepresentationChange,
    isRequired: true,
    children: (
      <RevitClassificationSection
        revitFamilyCategoryList={revitFamilyCategoryList}
        selectedCategory={revitFamilyCategory}
        revitFamily={localRevitFamily}
        handleOnSelectCategory={handleOnSelectCategory}
        handleRevitFamilyChange={handleRevitFamilyChange}
      />
    ),
  };

  const BOM: OutputProps = {
    outputType: OutputType.BOM,
    title: text.outputsBillOfMaterials,
    acronyms: [text.outputsCSV],
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.BOM) || [],
    handleRepresentationChange,
  };

  const IAM: OutputProps = {
    outputType: OutputType.IAM,
    title: text.outputsInventorModel,
    acronyms: [text.outputsIAM],
    representations: [text.outputAllRepresentations],
    selectedRepresentations: selectedOutputRepresentationsMap.has(OutputType.IAM) ? [text.outputAllRepresentations] : [],
    showExcludeIntellectualProperty: true,
    handleRepresentationChange,
    tooltipMessage: text.outputsIAMTooltipMessage,
  };

  const SAT: OutputProps = {
    outputType: OutputType.SAT,
    acronyms: [text.outputsSAT],
    fileType: text.outputsSATFileType,
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.SAT) || [],
    handleRepresentationChange,
  };

  const STEP: OutputProps = {
    outputType: OutputType.STEP,
    acronyms: [text.outputsSTEP],
    fileType: text.outputsSTEPFileType,
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.STEP) || [],
    handleRepresentationChange,
  };

  const GLB: OutputProps = {
    outputType: OutputType.GLB,
    acronyms: [text.outputsGLB],
    fileType: text.outputsGLBFileType,
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.GLB) || [],
    handleRepresentationChange,
  };

  const groupedNeutralOutputs: OutputProps[] = [SAT, STEP, GLB];
  const individualOutputs: Map<string, OutputProps> = new Map([
    [text.outputsRevitFamily, RFA],
    [text.outputsBillOfMaterials, BOM],
    [text.outputsInventorModel, IAM],
  ]);

  const outputsSectionOrderTitles: string[] = [
    text.outputsRevitFamily,
    text.outputsInventorModel,
    text.outputsDrawing2D,
    text.outputsBillOfMaterials,
    text.outputsNeutralFormat,
  ];

  return (
    <OutputsWrapper>
      {outputsSectionOrderTitles.map((outputSectionTitle, index) => {
        if (outputSectionTitle === text.outputsDrawing2D) {
          return (
            <>
              <Drawing2DOutput />
              <DividerWrapper />
            </>
          );
        }

        if (outputSectionTitle === text.outputsNeutralFormat) {
          return (
            <>
              <GroupedOutputs title={text.outputsNeutralFormat} outputs={groupedNeutralOutputs} />
            </>
          );
        }

        const output = individualOutputs.get(outputSectionTitle);
        if (!output) {
          return null;
        }

        return (
          <div key={output.title}>
            <Output
              outputType={output.outputType}
              title={output.title}
              acronyms={output.acronyms}
              representations={output.representations}
              selectedRepresentations={output.selectedRepresentations}
              handleRepresentationChange={output.handleRepresentationChange}
              disabled={output.disabled}
              isRequired={output.isRequired}
              {...(output.showExcludeIntellectualProperty && {
                showExcludeIntellectualProperty: output.showExcludeIntellectualProperty,
              })}
              {...(output.tooltipMessage && { tooltipMessage: output.tooltipMessage })}
            >
              {output.children}
            </Output>
            {index !== individualOutputs.size - 1 && <DividerWrapper />}
          </div>
        );
      })}
    </OutputsWrapper>
  );
};

export default OutputsTab;
