import { useAddinVersion } from '@mid-react-common/addins';
import {
  AboutInfoMenu,
  initialModalState,
  ModalContext,
  NOTIFICATION_STATUSES,
  NotificationContext,
  TopHeader,
  unsavedChangesWarningMessage,
} from '@mid-react-common/common';
import text from 'inventor.text.json';
import { openMidWebapp, PublishStatus } from 'mid-addin-lib';
import React, { useContext } from 'react';
import DataContext from '../../context/DataStore/Data.context';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import TabProgressContext from '../../context/TabProgressStore/TabProgress.context';
import { validateProductDefinitionName } from '../../utils/productDefinition';
import Initial from './SubHeaders/Initial';
import ManageProducts from './SubHeaders/ManageProducts';
import ProductDefinitionConfigurationHeader from './SubHeaders/ProductDefinitionConfigurationHeader';
import PublishingHeader from './SubHeaders/PublishingHeader';
import { PublishLocationScreenHeader } from './SubHeaders/PublishLocationScreenHeader';

const Header: React.FC = (): JSX.Element => {
  const { currentScreen, setCurrentScreen } = useContext(NavigationContext);
  const {
    currentProductDefinition,
    productDefinitionHasUnsavedChanges,
    resetCurrentProductDefinition,
    currentProductDefinitionPublishStatus,
    setCurrentProductDefinitionPublishStatus,
  } = useContext(DataContext);
  const { resetToInitialActiveTab } = useContext(TabProgressContext);
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);

  const { addinVersion, desktopApplicationVersion } = useAddinVersion();

  // Should be used inside a `WithConfirmation` handler
  const _handleOpenProductDefinitionsSelection = () => {
    setCurrentScreen(Screens.PRODUCT_DEFINITION_SELECTION);
    resetCurrentProductDefinition();
  };

  const handleManageProductsClick = (): void => {
    setCurrentScreen(Screens.MANAGE_PRODUCTS);
  };

  const handlePublishClick = async (): Promise<void> => {
    const productDefinitionValidationResult = await validateProductDefinitionName(
      currentProductDefinition.name,
      currentProductDefinition.id,
    );
    if (productDefinitionValidationResult.error) {
      showNotification({
        message: productDefinitionValidationResult.cause || '',
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      resetToInitialActiveTab();
      return;
    }
    setCurrentScreen(Screens.PUBLISHING);
  };

  const handleOpenProductDefinitionsSelectionClick = (): void => {
    if (productDefinitionHasUnsavedChanges) {
      setModalState({
        ...initialModalState,
        isOpen: true,
        title: text.unsavedChanges,
        message: unsavedChangesWarningMessage,
        onConfirmCallback: _handleOpenProductDefinitionsSelection,
      });
    } else {
      _handleOpenProductDefinitionsSelection();
    }
  };

  const handleGoBackToProductDefinitions = () => {
    setCurrentProductDefinitionPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_SELECTION);
  };

  const handleEditProductDefinitionClick = () => {
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
  };

  if (currentScreen === Screens.PUBLISHING && currentProductDefinitionPublishStatus !== PublishStatus.IDLE) {
    return (
      <>
        {currentProductDefinitionPublishStatus === PublishStatus.LOADING && (
          <PublishingHeader title={text.headerPublishingInProgress} titleColor="primary.main" />
        )}
        {currentProductDefinitionPublishStatus === PublishStatus.COMPLETE && (
          <PublishingHeader
            title={text.headerPublishingComplete}
            subtitle={text.subHeaderPublishingComplete}
            titleColor="success.main"
            addinVersion={addinVersion}
            desktopApplicationVersion={desktopApplicationVersion}
            handleGoBackToProductDefinitions={handleGoBackToProductDefinitions}
          />
        )}
        {currentProductDefinitionPublishStatus === PublishStatus.FAILURE && (
          <PublishingHeader
            title={text.headerPublishingFailed}
            subtitle={text.subHeaderPublishingFailed}
            titleColor="error.main"
            addinVersion={addinVersion}
            desktopApplicationVersion={desktopApplicationVersion}
            handleGoBackToProductDefinitions={handleGoBackToProductDefinitions}
          />
        )}
      </>
    );
  }

  return (
    <TopHeader>
      {currentScreen === Screens.PRODUCT_DEFINITION_SELECTION && (
        <Initial handleMidWebPortalClick={openMidWebapp} handleManageProductsClick={handleManageProductsClick} />
      )}
      {currentScreen === Screens.PRODUCT_DEFINITION_CONFIGURATION && (
        <ProductDefinitionConfigurationHeader
          handleOpenProductDefinitionsSelectionClick={handleOpenProductDefinitionsSelectionClick}
          handlePublishClick={handlePublishClick}
        />
      )}
      {currentScreen === Screens.MANAGE_PRODUCTS && (
        <ManageProducts handleOpenProductDefinitionsSelectionClick={handleOpenProductDefinitionsSelectionClick} />
      )}
      {currentScreen === Screens.PUBLISHING && currentProductDefinitionPublishStatus === PublishStatus.IDLE && (
        <PublishLocationScreenHeader
          handleEditProductDefinitionClick={handleEditProductDefinitionClick}
          handleOpenProductDefinitionsSelectionClick={handleOpenProductDefinitionsSelectionClick}
        />
      )}
      <AboutInfoMenu
        isTargetBlank
        webComponentVersion={import.meta.env.VITE_IVTW_VERSION || ''}
        addinVersion={addinVersion}
        desktopApplicationVersion={desktopApplicationVersion}
      />
    </TopHeader>
  );
};

export default Header;
